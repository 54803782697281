import { isNull } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import styles from './AIManager.module.scss';
import { getSelectedAIAgreementTypeId, setIsEditingDocumentQueries, setSelectedAIAgreementType } from './store';
import { Dropdown, DropdownOption } from '../../shared/dropdown/Dropdown';
import { Position, PositionModal } from '../../shared/modal/PositionModal';

export interface AIAgreementTypeModalProps {
    isOpen: boolean;
    options: DropdownOption[];
    closeModal: () => void;
    position: Position | null;
}

export const AIAgreementTypeModal: React.FC<AIAgreementTypeModalProps> = ({ isOpen, options, closeModal, position }) => {

    const dispatch = useAppDispatch();

    const selectedAgreementTypeId = useAppSelector(getSelectedAIAgreementTypeId);

    const selectedAgreementTypeOption = useMemo(() => !isNull(selectedAgreementTypeId) ? options.find(({ value }) => parseInt(value) === selectedAgreementTypeId)! : null, [selectedAgreementTypeId, options]);

    const updateSelectedAgreementType = useCallback((dropdownValue: DropdownOption | Options<DropdownOption> | null) => {
        let value: number | null = null;
        if (!isNull(dropdownValue)) {
            value = parseInt((dropdownValue as DropdownOption).value);
            dispatch(setSelectedAIAgreementType(value!, true));
            closeModal();
            dispatch(setIsEditingDocumentQueries(true));
        }
    }, [dispatch, closeModal]);

    return (
        <PositionModal
            isOpen={isOpen}
            closeModal={closeModal}
            position={position}
            testId='admin-ai-agreement-select'
            width='310px'
            height='65px'
        >
            <div className={styles.agreementSelectWrapper}>
                <div className={styles.agreementSelectTitle}>Select Agreement Type</div>
                <Dropdown
                    value={selectedAgreementTypeOption}
                    options={options}
                    onChange={updateSelectedAgreementType}
                    isClearable={false}
                    menuPortalTarget={document.body}
                />
            </div>
        </PositionModal>
    );
};
