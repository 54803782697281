import React, { useMemo, useCallback } from 'react';
import classnames from 'classnames';

import { OverflowTooltip, InformationTooltip, CustomTooltip } from '../../../../../shared/tooltip';
import styles from '../../../../../shared/datasets/SharedStyling.module.scss';
import { AgencyDatasetFieldType, TableDatasetFieldType } from '../../../../store';
import { widthsByType } from '../../../../../admin/dataset-builder/preview/tablePreview/TableHeader';
import { IconButton } from '../../../../../shared/button/IconButton';
import { CaretSide } from '../../../../../shared/icons';
import { InstanceIdentifier } from '../../../../store/mlTypes';
import { SelectedMLQuestion } from './MLTableInstance';

const { white } = styles;

interface MLTableHeaderProps {
    id: string;
    label: string;
    description: string;
    type: TableDatasetFieldType | AgencyDatasetFieldType;
    isFirstCell: boolean;
    columnCollapsed: boolean;
    toggleCollapseColumn: (id: string) => void;
    aliasLabel?: string;
    refLabel?: string
    showRef?: boolean;
    columnMlData: InstanceIdentifier | undefined;
    mlQuestionKey: SelectedMLQuestion | null;
}

export const MLTableHeader: React.FC<MLTableHeaderProps> = ({ label, description, id, type, isFirstCell, aliasLabel, columnCollapsed, toggleCollapseColumn, refLabel, showRef, columnMlData, mlQuestionKey }) => {
    const cellWidth = useMemo(() => columnCollapsed ? '16px' : `${widthsByType[type]}px`, [columnCollapsed, type]);
    const columnLabel = useMemo(() => aliasLabel || label || 'Label...', [aliasLabel, label]);
    const toggleColumn = useCallback(() => toggleCollapseColumn(id), [toggleCollapseColumn, id]);
    const highlightCell = useMemo(() => mlQuestionKey && mlQuestionKey.systemId === columnMlData?.systemId, [mlQuestionKey, columnMlData]);

    const headerContent = useMemo(() => columnCollapsed ? (
        <div className={styles.cellHeader}>
            <CustomTooltip overlayText={columnLabel}>
                <div className={styles.headerIconWrapper}>
                    <IconButton icon={CaretSide} onClick={toggleColumn} color={white} fontSize={14} />
                </div>
            </CustomTooltip>
        </div>
    ) : (
        <div className={styles.cellHeader}>
            <OverflowTooltip className={classnames(styles.headerLabel, { [styles.emptyHeaderLabel]: !label })} overlayText={columnLabel} testId={`table-header-${id}-label`} />
            <div className={styles.iconsWrapper}>
                {aliasLabel && <InformationTooltip label='L' content={label} labelColor={white} />}
                {description && <InformationTooltip content={description} labelColor={white} />}
                {refLabel && showRef && <InformationTooltip content={refLabel} label='?' labelColor={white} />}
                <div className={styles.flippedIcon}><IconButton icon={CaretSide} onClick={toggleColumn} color={white} fontSize={14} /></div>
            </div>
        </div>
    ), [columnCollapsed, columnLabel, aliasLabel, description, refLabel, showRef, toggleColumn, label, id]);

    return (
        <div
            className={classnames(styles.tableHeaderWrapper, {
                [styles.firstCell]: isFirstCell,
                [styles.selectedTableHeader]: highlightCell
            })}
            style={{ width: cellWidth, minWidth: cellWidth }}
        >
            {headerContent}
        </div>
    );
};
