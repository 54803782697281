import Fuse from 'fuse.js';
import { findLast, flattenDeep, isEqual, isNull, isUndefined, last, max, set, uniq } from 'lodash/fp';
import { toast } from 'react-toastify';
import { push } from 'redux-first-history';
import { all, call, debounce, fork, put, select, takeEvery, takeLeading } from 'redux-saga/effects';
import { v4 } from 'uuid';

import { datasetInstanceExistsForDocumentId, fetchAgencyAnnexDetails, fetchDatasetId, fetchDatasetInstance, fetchECSDatasetId, fetchInstanceHierarchy, fetchTimelineInstances, generateECSTable, upsertDatasetInstance } from '../../../../services/datasetInstance';
import { deleteSecondaryDocument, getDocumentById, updateVersionConfirmed } from '../../../../services/document';
import { formatDateTime, isSameOrAfter } from '../../../../utils/luxon';
import { getIsMLReadyAgreementType } from '../../../admin/documents/store';
import { BaseEntity } from '../../../admin/entity/store';
import { HiddenFields } from '../../../admin/my-datasets/store';
import { MyRiskTolerance, RiskTolerance } from '../../../admin/risk-tolerance/store';
import { DocumentAnnexDefinition, InstanceAnnexDefinition, fetchAgencyFieldsStarted, fetchAllAnnexDefinitionsStarted, setInstanceAnnexDefinitions } from '../../../agency-annex/store';
import { getPathname, getUserId, getUserRole } from '../../../auth/login/store';
import { UserRole, adminRoles } from '../../../constants/permittedRoles';
import { ArkDocument, DocumentLinkDetails, DocumentType, SelectedDocument, getDocumentInView, getDocumentLinkDetails, openDocumentAndInstance, openSecondaryDocumentStarted, resetSecondaryDocument, resetSupportDocuments, setDocumentAnalysisSelectedPage, setSecondaryDocument, toggleDocumentInView, updateOriginalDocument } from '../../../documents/my-documents/store';
import { DatasetFieldType } from '../../store';
import { InstanceMLData } from '../../store/mlTypes';
import { calculateOpenFieldSections, compareInstanceFields, getAllFlattenedSearchFields, updateFieldsUpdated } from '../utils';
import {
    calculateSearchFieldSections,
    confirmMLDatasetInstanceFailed,
    deleteSecondaryDocumentFailed,
    deleteSecondaryDocumentStarted,
    deleteSecondaryDocumentSuccessful,
    editDatasetInstance,
    expandAllDatasetFieldSections,
    fetchECSDatasetIdFailed,
    fetchECSDatasetIdSuccessful,
    generateNewECSTableFailed,
    generateNewECSTableSuccessful,
    instanceHasUpdated, modalInstanceHasUpdated, openDatasetDefinitionFailed, openDatasetDefinitionStarted, openDatasetDefinitionSuccessful,
    openDatasetInstanceById, openModalDatasetFailed, openModalDatasetStarted, openModalDatasetSuccessful, openTimelineInstanceFailed, openTimelineInstanceStarted,
    openTimelineInstanceSuccessful, scrollToSection, setAgencyLinkedEntities, setAllSearchFieldSections, setAnnexFieldIds, setDatasetInstanceRiskTolerance, setFieldsUpdated, setFuzzyMatchSearchValue, setHiddenFields, setModalFieldsUpdated, setParentDatasetId, setSearchFieldSection, setSearchFuzzyMatches, setTimeline, setTimelineInstance, toggleMLDataModal, toggleShowLegacy, updateDatasetHierarchy, updateDatasetInstance,
    updateFieldValue,
    updateLastModifiedFields,
    updateModalLastModifiedFields,
    upsertDatasetInstanceFailed,
    upsertDatasetInstanceStarted,
    upsertDatasetInstanceSuccessful,
    upsertModalDatasetFailed,
    upsertModalDatasetSuccessful
} from './actions';
import { getAllSectionsAndFields, getCurrentInstances, getCurrentModalInstance, getDatasetInstanceHierarchy, getDatasetInstanceTimeline, getECSDatasetId, getInstanceUpdated, getIsSaving, getIsUpdating, getMLData, getModalInstanceParentDetails, getOpenFieldsAndSections, getParentDatasetId, getSavedInstances, getSavedModalInstance, getShowLegacy } from './selectors';
import { isFormDatasetInstance } from './typeAssertions';
import { AnnexInstanceFields, DatasetInstance, DatasetInstanceActionTypes, DatasetInstanceTimeline, FormDatasetInstance, Hierarchy, Instance, ModalInstanceParentDetails, OpenFieldSection, SearchFieldSection, SingleInstanceField, TimelineInstance } from './types';

const getInstance = (allInstances: TimelineInstance[], executedDate: string | undefined): TimelineInstance => {
    // Open the instance which is in draft and not yet published
    const instanceInDraft = allInstances.find(({ isDraft }) => isDraft);

    if (!executedDate) {
        return instanceInDraft || last(allInstances)!;
    }

    const instance = findLast(datasetInstance => isSameOrAfter(executedDate, datasetInstance.executedDate), allInstances)!;
    return instance;
};

export function* attemptOpenTimelineInstance({ payload }: ReturnType<typeof openTimelineInstanceStarted>) {
    try {
        const { timelineInstance, preventRedirect } = payload;
        const { datasetInstances, hierarchy, agencyLinkedEntities, hiddenFields, mlData, riskToleranceDefinitions, annexDefinitions }: { datasetInstances: Instance[]; hierarchy: Hierarchy[]; agencyLinkedEntities: BaseEntity[]; hiddenFields: HiddenFields; mlData: InstanceMLData | null; riskToleranceDefinitions: (RiskTolerance | MyRiskTolerance)[]; annexDefinitions: DocumentAnnexDefinition[]; } = yield call(fetchInstanceHierarchy, { instance: timelineInstance });

        const annexInstances = datasetInstances.map(({ instance }) => instance).filter(({ annexDefinitionId }) => !isNull(annexDefinitionId));
        const parentDatasetId = parseInt(hierarchy.find(({ parentId }) => isNull(parentId))!.datasetId);
        const instance = datasetInstances.map(({ instance }) => instance).find(({ datasetId }) => datasetId === parentDatasetId)!;
        yield put(setParentDatasetId(parentDatasetId));
        const { originalDocumentId, documentId, datasetInstanceId } = instance;
        if (annexInstances.length > 0) {
            const { documentNameId, agencyDatasetId }: { documentNameId: number; agencyDatasetId: number | null; } = yield call(fetchAgencyAnnexDetails, { documentId });
            const annexSectionId = !isNull(instance) && isFormDatasetInstance(instance) && instance.datasetSections.find(({ id }) => id.includes('annex-section'))?.id || null;
            const instanceAnnexDefinitions: InstanceAnnexDefinition[] = !isNull(annexSectionId) && isFormDatasetInstance(instance) && (instance.datasetFields[annexSectionId] as SingleInstanceField[]).map(({ label, settings, id }) => {
                const annexDefinitionId = parseInt(settings.datasetLinked as string);
                const existingInfo = annexDefinitions.find(definition => definition.annexDefinitionId === annexDefinitionId);
                const startPage = existingInfo?.startPage || null;
                const endPage = existingInfo?.endPage || null;
                const extractedData = existingInfo?.extractedData || null;
                const extractionInProgress = existingInfo?.extractionInProgress || 0;
                return { label, annexDefinitionId, fieldId: id!, startPage, endPage, extractedData, extractionInProgress };
            }) || [];
            if (!isNull(agencyDatasetId)) {
                yield put(fetchAgencyFieldsStarted(agencyDatasetId));
            }
            yield put(setInstanceAnnexDefinitions(instanceAnnexDefinitions));
            yield put(fetchAllAnnexDefinitionsStarted(documentNameId.toString()));
            const annexFieldIds = flattenDeep(annexInstances.map(annexInstance => Object.values(annexInstance.datasetFields as AnnexInstanceFields)[0].map(({ id }) => id)));
            yield put(setAnnexFieldIds(annexFieldIds));
        }
        if (originalDocumentId && documentId && originalDocumentId !== documentId) {
            const secondaryDocument: ArkDocument = yield call(getDocumentById, { documentId });
            yield put(openSecondaryDocumentStarted(secondaryDocument));
            yield put(setSecondaryDocument(secondaryDocument));
            yield put(toggleDocumentInView(SelectedDocument.SECONDARY));
        } else {
            yield put(resetSecondaryDocument());
            yield put(toggleDocumentInView(SelectedDocument.ORIGINAL));
        }
        yield put(resetSupportDocuments());
        if (datasetInstanceId && !preventRedirect) {
            yield put(push(`/documents/analysis/${payload.originalDocumentId}/${datasetInstanceId}`));
        }
        yield put(setHiddenFields(hiddenFields));
        yield put(calculateSearchFieldSections(datasetInstances, hierarchy, hiddenFields));
        yield put(setDatasetInstanceRiskTolerance(riskToleranceDefinitions));
        yield put(setAgencyLinkedEntities(agencyLinkedEntities));
        yield put(openTimelineInstanceSuccessful(datasetInstances, hierarchy, mlData));

        const isMLReadyAgreementType: boolean = yield select(getIsMLReadyAgreementType(mlData?.agreementTypeId));
        const openMLModal = !isNull(mlData) && (mlData.versionConfirmed < mlData.version) && isMLReadyAgreementType;
        yield put(toggleMLDataModal(openMLModal));
    } catch (e) {
        yield put(openTimelineInstanceFailed((e as Error).message));
    }
}

export function* attemptOpenDatasetInstanceById({ payload }: ReturnType<typeof openDatasetDefinitionStarted>) {
    const { executedDate, documentType, showLegacy } = payload;
    try {
        const { documentNameId, documentId }: DocumentLinkDetails = yield select(getDocumentLinkDetails);
        if (typeof documentNameId === 'number') {
            if (documentType && documentType === DocumentType.SUPPORT) {
                const updatedDocumentWithSupportDocuments: ArkDocument = yield call(getDocumentById, { documentId });
                yield put(updateOriginalDocument(updatedDocumentWithSupportDocuments));
            }
            const datasetId: number = yield call(fetchDatasetId, { documentNameId });
            const timeline: DatasetInstanceTimeline = yield call(fetchTimelineInstances, { datasetId, documentId, documentNameId, showLegacy });
            yield put(setTimeline(timeline));
            const { instances, datasetDefinition, originalDocumentId } = timeline;
            const datasetInstance = getInstance(instances, executedDate);
            yield put(openTimelineInstanceStarted(datasetInstance, originalDocumentId));
            yield put(openDatasetDefinitionSuccessful(datasetDefinition));
        }
    } catch (e) {
        yield put(openDatasetDefinitionFailed((e as Error).message));
    }
}

export function* attemptOpenDatasetInstanceByInstanceId({ payload }: ReturnType<typeof openDatasetInstanceById>) {
    try {
        const { datasetInstanceId, showLegacy, originalDocumentId, preventRedirect } = payload;
        const documentLinkDetails: DocumentLinkDetails = yield select(getDocumentLinkDetails);
        const currentShowLegacy: boolean = yield select(getShowLegacy);
        const { documentNameId } = documentLinkDetails;
        const documentId = originalDocumentId || documentLinkDetails.documentId;
        if (typeof documentNameId === 'number') {
            const { instanceExists }: { instanceExists: boolean; } = yield call(datasetInstanceExistsForDocumentId, { datasetInstanceId, documentId, documentNameId });
            if (!instanceExists) {
                yield put(push('/documents/my-documents'));
                toast.error('Could not find instance matching that ID. Please try again.');
                return;
            } else {
                const datasetId: number = yield call(fetchDatasetId, { documentNameId });
                const timeline: DatasetInstanceTimeline = yield call(fetchTimelineInstances, { datasetId, documentId, documentNameId, showLegacy });
                const { instances, datasetDefinition, originalDocumentId } = timeline;
                const datasetInstance: TimelineInstance = instances.find(({ datasetInstanceId }) => datasetInstanceId === parseInt(payload.datasetInstanceId))!;
                if (!isUndefined(showLegacy) && showLegacy !== currentShowLegacy) {
                    yield put(toggleShowLegacy(showLegacy));
                }
                yield put(setTimeline(timeline));
                yield put(openTimelineInstanceStarted(datasetInstance, originalDocumentId, preventRedirect));
                yield put(openDatasetDefinitionSuccessful(datasetDefinition));
            }
        }
    } catch (e) {
        yield put(push('/home'));
        toast.error('Unable to open dataset instance. Please try again.');
    }
}

export function* attemptSetTimelineInstance({ payload }: ReturnType<typeof setTimelineInstance>) {
    try {
        const { originalDocumentId, timelineInstance, preventRedirect } = payload;
        const savedInstances: Instance[] = yield select(getSavedInstances);
        const parentDatasetId: number = yield select(getParentDatasetId);
        const showLegacy: boolean = yield select(getShowLegacy);
        const instanceOriginalDocumentId = savedInstances.find(({ instance: { datasetId } }) => datasetId === parentDatasetId)!.instance.originalDocumentId;
        const shouldSwitchOriginalDocument = (instanceOriginalDocumentId === originalDocumentId && timelineInstance.isLegacy) || (instanceOriginalDocumentId !== originalDocumentId && !timelineInstance.isLegacy);
        if (shouldSwitchOriginalDocument) {
            yield put(openDocumentAndInstance(originalDocumentId.toString(), timelineInstance.datasetInstanceId.toString(), showLegacy));
        } else {
            yield put(openTimelineInstanceStarted(timelineInstance, originalDocumentId, preventRedirect));
        }
    } catch (e) {
        yield put(push('/home'));
        toast.error('Something went wrong. Please try again.');
    }
}

export function* attemptToggleLegacyTimeline({ payload }: ReturnType<typeof toggleShowLegacy>) {
    try {
        const showLegacy = payload;
        const timeline: DatasetInstanceTimeline = yield select(getDatasetInstanceTimeline);
        const savedInstances: Instance[] = yield select(getSavedInstances);
        const parentDatasetId: number = yield select(getParentDatasetId);
        const parentInstance = savedInstances.find(({ instance: { datasetId } }) => datasetId === parentDatasetId)!.instance;
        const replicatedDocumentId = timeline.replicatedOriginalDocumentId;
        if (replicatedDocumentId) {
            const originalDocumentId = timeline.instances.find(({ documentType, documentId }) => documentType === DocumentType.ORIGINAL && documentId !== replicatedDocumentId)!.documentId;
            if (originalDocumentId !== parentInstance.originalDocumentId && !showLegacy) {
                yield put(openDocumentAndInstance(originalDocumentId.toString(), undefined));
            } else {
                yield put(openDatasetInstanceById(parentInstance.datasetInstanceId!.toString(), showLegacy));
            }
        }
    } catch (e) {
        toast.error('Something went wrong. Please try again.');
    }
}

export function* attemptOpenTableDatasetInstance({ payload }: ReturnType<typeof openModalDatasetStarted>) {
    const { datasetId, instanceId, childDatasetId } = payload;
    try {
        const currentInstances: Instance[] = yield select(getCurrentInstances);
        const { documentId } = currentInstances.find(({ instance }) => instance.datasetId === datasetId)!.instance;
        const datasetInstance: DatasetInstance = yield call(fetchDatasetInstance, { documentId, datasetId: childDatasetId, datasetInstanceId: instanceId });
        yield put(openModalDatasetSuccessful(datasetInstance));
    } catch (e) {
        yield put(openModalDatasetFailed((e as Error).message));
    }
}

const getLowestDepthDatasets = (hierarchy: Hierarchy[], instances: Instance[]) => {
    const updatedOnlyHierarchy = hierarchy.filter(({ hasUpdated }) => hasUpdated);
    if (updatedOnlyHierarchy.length) {
        const maxDepth = Math.max(...updatedOnlyHierarchy.map(({ depthIndex }) => depthIndex));
        const datasetIds = updatedOnlyHierarchy.filter(({ depthIndex }) => depthIndex === maxDepth).map(({ datasetId, fieldId }) => ({ datasetId, fieldId }));
        const datasetInstances = instances.filter(({ instance: { datasetId, annexDefinitionId }, parentFieldId }) => {
            const id: number = isNull(annexDefinitionId) ? datasetId! : annexDefinitionId;
            return !!datasetIds.find(dataset => isEqual(dataset, { datasetId: id.toString(), fieldId: parentFieldId }));
        });
        return { maxDepth, datasetInstances };
    }
    const maxDepth = 0;
    const datasetId = hierarchy.find(({ depthIndex }) => depthIndex === maxDepth)!.datasetId;
    const datasetInstances = instances.filter(({ instance }) => instance.datasetId?.toString() === datasetId);
    return { maxDepth, datasetInstances };
};

const getParentDetails = (instance: DatasetInstance, currentInstances: DatasetInstance[], hierarchy: Hierarchy[], parentFieldId: string) => {
    const id = isNull(instance.annexDefinitionId) ? instance.datasetId! : instance.annexDefinitionId;
    const instanceInHierarchy = hierarchy.find(({ datasetId, fieldId }) => datasetId === id.toString() && fieldId === parentFieldId);
    if (instanceInHierarchy && instanceInHierarchy.parentId) {
        const { parentId, sectionId, fieldId } = instanceInHierarchy;
        const parentFieldId = hierarchy.find(({ datasetId, isAnnexDataset }) => datasetId === parentId && !isAnnexDataset)!.fieldId;
        const instanceToUpdate = currentInstances.find(({ datasetId }) => datasetId!.toString() === parentId) as FormDatasetInstance;
        const index = instanceToUpdate.datasetFields[sectionId].map(({ id }) => id!).indexOf(fieldId);
        return { index, sectionId, datasetId: parseInt(parentId), parentFieldId };
    }
};

const getUpdatedHierarchy = (hierarchy: Hierarchy[], datasetInstance: Instance, parentId?: number) => hierarchy.map(dataset => {
    const { instance, parentFieldId } = datasetInstance;
    const datasetId = isNull(instance.annexDefinitionId) ? instance.datasetId! : instance.annexDefinitionId;
    if (dataset.isAnnexDataset) {
        if (!isNull(instance.annexDefinitionId) && (parseInt(dataset.datasetId) === datasetId)) {
            return set('hasUpdated', false, dataset);
        }
        return dataset;
    }
    if ((parseInt(dataset.datasetId) === datasetId) && (dataset.fieldId === parentFieldId)) {
        return set('hasUpdated', false, dataset);
    }
    if (parseInt(dataset.datasetId) === parentId) {
        return set('hasUpdated', true, dataset);
    }
    return dataset;
});

export function* saveAndUpdateParent(datasetInstance: Instance, isUpdating: boolean, isDraft: boolean, isAdminUser: boolean) {
    const { instance } = datasetInstance;
    const savedInstances: Instance[] = yield select(getSavedInstances);
    const currentInstances: Instance[] = yield select(getCurrentInstances);
    const savedDatasetInstances = savedInstances.map(({ instance }) => instance);
    const currentDatasetInstances = currentInstances.map(({ instance }) => instance);
    const executedDate = formatDateTime(savedDatasetInstances.sort((a, b) => new Date(b.executedDate).valueOf() - new Date(a.executedDate).valueOf())[0].executedDate);
    const hierarchy: Hierarchy[] = yield select(getDatasetInstanceHierarchy);
    const parentDetails = getParentDetails(instance, currentDatasetInstances, hierarchy, datasetInstance.parentFieldId);
    const parentUpdatedDatasetFields = currentInstances.find(({ instance }) => instance.datasetId === parentDetails?.datasetId)?.instance.fieldsUpdated.filter(({ type }) => type === DatasetFieldType.DATASET).map(({ value }) => value as string);
    const isAdminUpdate = isAdminUser && !!instance.datasetInstanceId && !instance.isDraft && (!parentDetails || (!!parentUpdatedDatasetFields && parentUpdatedDatasetFields.includes(instance.datasetInstanceId.toString())));
    const isUpdate = isUpdating || isAdminUpdate;
    const updatedInstance = updateFieldsUpdated(instance, savedDatasetInstances, isUpdate, executedDate);
    const datasetInstanceId: string = yield call(upsertDatasetInstance, { datasetInstance: updatedInstance, isUpdate, isDraft });
    if (parentDetails) {
        const { datasetId, index, sectionId, parentFieldId } = parentDetails;
        yield put(updateFieldValue(datasetId, parentFieldId, datasetInstanceId, index, sectionId));
    }
    const updatedHierarchy = getUpdatedHierarchy(hierarchy, datasetInstance, parentDetails?.datasetId);
    yield put(updateDatasetHierarchy(updatedHierarchy));
}

export function* saveParent(datasetInstance: Instance, isUpdating: boolean, isDraft: boolean, isAdminUser: boolean) {
    const isUpdate = isUpdating || (isAdminUser && !!datasetInstance.instance.datasetInstanceId && !datasetInstance.instance.isDraft);
    const savedInstances: Instance[] = yield select(getSavedInstances);
    const savedDatasetInstances = savedInstances.map(({ instance }) => instance);
    const updatedInstance = updateFieldsUpdated(datasetInstance.instance, savedDatasetInstances, isUpdate);
    const datasetInstanceId: string = yield call(upsertDatasetInstance, { datasetInstance: updatedInstance, isUpdate, isDraft });
    return datasetInstanceId;
}

export function* saveAllInstances({ payload }: ReturnType<typeof upsertDatasetInstanceStarted>) {
    try {
        const isDraft = payload;
        const userRole: UserRole = yield select(getUserRole);
        const isUpdating: boolean = yield select(getIsUpdating);
        const showLegacy: boolean = yield select(getShowLegacy);
        const isAdminUser = adminRoles.includes(userRole!);
        const currentInstances: Instance[] = yield select(getCurrentInstances);
        const hierarchy: Hierarchy[] = yield select(getDatasetInstanceHierarchy);
        const lowestDepthDatasets = getLowestDepthDatasets(hierarchy, currentInstances);
        let maxDepth = lowestDepthDatasets.maxDepth;
        let datasetInstances = lowestDepthDatasets.datasetInstances;
        while (maxDepth > 0) {
            for (const instance of datasetInstances) {
                yield call(saveAndUpdateParent, instance, isUpdating, isDraft, isAdminUser);
            }
            const currentInstances: Instance[] = yield select(getCurrentInstances);
            const hierarchy: Hierarchy[] = yield select(getDatasetInstanceHierarchy);
            const lowestDepthDatasets = getLowestDepthDatasets(hierarchy, currentInstances);
            maxDepth = lowestDepthDatasets.maxDepth;
            datasetInstances = lowestDepthDatasets.datasetInstances;
        }

        const parentInstance = datasetInstances[0];
        const datasetInstanceId: string = yield call(saveParent, parentInstance, isUpdating, isDraft, isAdminUser);
        yield put(push(`/documents/analysis/${parentInstance.instance.originalDocumentId}/${datasetInstanceId}`));
        yield put(upsertDatasetInstanceSuccessful());
        yield put(openDatasetInstanceById(datasetInstanceId, showLegacy));
        toast('Saved successfully');
    } catch (e) {
        yield put(upsertDatasetInstanceFailed((e as Error).message));
    }
}

export function* confirmMLDatasetInstance() {
    try {
        const isDraft = true;
        const mlData: InstanceMLData = yield select(getMLData);
        const newVersionConfirmed = max(mlData.instanceMLData.map(({ version }) => version)) || mlData.versionConfirmed;
        yield call(updateVersionConfirmed, { versionConfirmed: newVersionConfirmed, documentId: mlData.documentId });
        yield put(upsertDatasetInstanceStarted(isDraft));
        yield put(toggleMLDataModal(false));
    } catch (e) {
        yield put(confirmMLDatasetInstanceFailed((e as Error).message));
    }
}

export function* checkInstanceUpdated(datasetInstance: Instance, savedInstances: Instance[], hierarchy: Hierarchy[], isUpdating: boolean, userId: number, isAdminUser: boolean) {
    try {
        const isSaving: boolean = yield select(getIsSaving);
        if (!isSaving) {
            const { instance, parentFieldId } = datasetInstance;
            const datasetId: number = isNull(instance.annexDefinitionId) ? instance.datasetId! : instance.annexDefinitionId;
            if (instance.datasetInstanceId) {
                const savedDatasetInstances = savedInstances.map(({ instance }) => instance);
                const parentDetails = getParentDetails(instance, savedDatasetInstances, hierarchy, datasetInstance.parentFieldId);
                const parentUpdatedDatasetFields = savedInstances.find(({ instance }) => instance.datasetId === parentDetails?.datasetId)?.instance.fieldsUpdated.filter(({ type }) => type === DatasetFieldType.DATASET).map(({ value }) => value as string);
                const isAdminUpdate = isAdminUser && !instance.isDraft && (!parentDetails || (!!parentUpdatedDatasetFields && parentUpdatedDatasetFields.includes(instance.datasetInstanceId.toString())));
                const existingInstance = savedDatasetInstances.find(({ datasetInstanceId }) => datasetInstanceId === instance.datasetInstanceId)!;
                const isUpdate = isUpdating || isAdminUpdate;
                const { updatedFields, unchangedFields, rowsRemoved } = compareInstanceFields(instance, existingInstance, isUpdate);
                const instanceUpdated = !!updatedFields.length || (!isUndefined(rowsRemoved) && rowsRemoved.length > 0);
                if (instanceUpdated) {
                    yield put(updateLastModifiedFields(datasetId, parentFieldId, updatedFields, userId));
                }
                yield put(setFieldsUpdated(datasetId, parentFieldId, [...unchangedFields, ...updatedFields]));
                yield put(instanceHasUpdated(datasetId, parentFieldId, instanceUpdated));
            } else {
                const { updatedFields, unchangedFields, rowsRemoved } = compareInstanceFields(instance);
                const instanceUpdated = !!updatedFields.length || (!isUndefined(rowsRemoved) && rowsRemoved.length > 0);
                if (instanceUpdated) {
                    yield put(updateLastModifiedFields(datasetId, parentFieldId, updatedFields, userId));
                }
                yield put(setFieldsUpdated(datasetId, parentFieldId, [...unchangedFields, ...updatedFields]));
                yield put(instanceHasUpdated(datasetId, parentFieldId, instanceUpdated));
            }
        }
    } catch (e) {
        toast.error('We encountered a problem checking if you had made any changes.');
    }
}

export function* checkAllInstancesUpdated() {
    try {
        const isUpdating: boolean = yield select(getIsUpdating);
        const userRole: UserRole = yield select(getUserRole);
        const isAdminUser = adminRoles.includes(userRole!);
        const savedInstances: Instance[] = yield select(getSavedInstances);
        const currentInstances: Instance[] = yield select(getCurrentInstances);
        const hierarchy: Hierarchy[] = yield select(getDatasetInstanceHierarchy);
        const userId: number = yield select(getUserId);
        yield all(currentInstances.map(instance => call(checkInstanceUpdated, instance, savedInstances, hierarchy, isUpdating, userId, isAdminUser)));
    } catch (e) {
        toast.error('We encountered a problem checking if you had made any changes.');
    }
}

export function* resetInstance({ payload }: ReturnType<typeof updateDatasetInstance | typeof editDatasetInstance>) {
    try {
        if (!payload) {
            const savedInstances: Instance[] = yield select(getSavedInstances);
            const hierarchy: Hierarchy[] = yield select(getDatasetInstanceHierarchy);
            const mlData: InstanceMLData | null = yield select(getMLData);
            const resetHierarchy = hierarchy.map(dataset => ({ ...dataset, hasUpdated: false }));
            yield put(openTimelineInstanceSuccessful(savedInstances, resetHierarchy, mlData));
        }
    } catch (e) {
        toast.error('Error');
    }
}

// Modal Instance
export function* checkModalInstanceUpdated() {
    try {
        const isUpdating: boolean = yield select(getIsUpdating);
        const savedInstance: DatasetInstance = yield select(getSavedModalInstance);
        const currentInstance: DatasetInstance = yield select(getCurrentModalInstance);
        const userId: number = yield select(getUserId);
        if (currentInstance.datasetInstanceId) {
            const { updatedFields, unchangedFields } = compareInstanceFields(currentInstance, savedInstance, isUpdating);
            const instanceUpdated = !!updatedFields.length;
            if (instanceUpdated) {
                yield put(updateModalLastModifiedFields(updatedFields, userId));
            }
            yield put(setModalFieldsUpdated([...unchangedFields, ...updatedFields]));
            yield put(modalInstanceHasUpdated(instanceUpdated));
        } else {
            const { updatedFields, unchangedFields } = compareInstanceFields(currentInstance);
            const instanceUpdated = !!updatedFields.length;
            if (instanceUpdated) {
                yield put(updateModalLastModifiedFields(updatedFields, userId));
            }
            yield put(setModalFieldsUpdated([...unchangedFields, ...updatedFields]));
            yield put(modalInstanceHasUpdated(instanceUpdated));
        }
    } catch (e) {
        toast.error('We encountered a problem checking if you had made any changes.');
    }
}

export function* saveModalInstance() {
    try {
        const savedInstance: DatasetInstance = yield select(getSavedModalInstance);
        const currentInstance: DatasetInstance = yield select(getCurrentModalInstance);
        const isUpdate: boolean = yield select(getIsUpdating);
        const updatedInstance = updateFieldsUpdated(currentInstance, [savedInstance], isUpdate);
        const parentDetails: ModalInstanceParentDetails = yield select(getModalInstanceParentDetails);
        const datasetInstanceId: string = yield call(upsertDatasetInstance, { datasetInstance: updatedInstance, isUpdate, isDraft: true });
        const { datasetId, index, rowId, parentFieldId } = parentDetails;
        yield put(updateFieldValue(datasetId, parentFieldId, datasetInstanceId, index, undefined, undefined, rowId));
        yield put(upsertModalDatasetSuccessful());
    } catch (e) {
        yield put(upsertModalDatasetFailed((e as Error).message));
    }
}

export function* attemptFetchECSDatasetId() {
    try {
        const ecsDatasetId: number = yield call(fetchECSDatasetId);
        yield put(fetchECSDatasetIdSuccessful(ecsDatasetId));
    } catch (e) {
        yield put(fetchECSDatasetIdFailed((e as Error).message));
    }
}

export function* attemptGenerateECSTable() {
    try {
        const ecsDatasetId: number = yield select(getECSDatasetId);
        const savedInstances: Instance[] = yield select(getSavedInstances);
        const ecsFormDataset = savedInstances.map(({ instance }) => instance).find(({ datasetId }) => datasetId === ecsDatasetId);
        const parentFieldId = v4();
        if (ecsFormDataset) {
            const firstField = Object.values(ecsFormDataset.datasetFields)[0][0] as SingleInstanceField;
            const ecsTableDatasetId = firstField.settings.datasetLinked!;
            const documentId = ecsFormDataset.documentId;
            const instance: Instance = yield call(generateECSTable, { parentFieldId, ecsTableDatasetId, documentId });
            const hierarchy: Hierarchy[] = yield select(getDatasetInstanceHierarchy);
            const siblingTableDataset = hierarchy.find(({ datasetId }) => datasetId === ecsTableDatasetId)!;
            const newTableHierarchy: Hierarchy = { ...siblingTableDataset, fieldId: parentFieldId, hasUpdated: false };
            const updatedHierarchy = [...hierarchy, newTableHierarchy];
            yield put(updateDatasetHierarchy(updatedHierarchy));
            yield put(generateNewECSTableSuccessful(instance, siblingTableDataset.sectionId));
        }
    } catch (e) {
        yield put(generateNewECSTableFailed((e as Error).message));
    }
}

export function* attemptDeleteSecondaryDocument({ payload }: ReturnType<typeof deleteSecondaryDocumentStarted>) {
    try {
        const { datasetInstanceId, documentId } = payload;
        const savedInstances: Instance[] = yield select(getSavedInstances);
        const pathname: string = yield select(getPathname);
        const instanceHasUpdated: boolean = yield select(getInstanceUpdated);
        const parentDatasetId: number = yield select(getParentDatasetId);
        const showLegacy: boolean = yield select(getShowLegacy);
        const urlInstanceId = pathname.split('/')[3];
        const savedInstanceIds = savedInstances.filter(({ instance }) => !!instance.datasetInstanceId).map(({ instance: { datasetInstanceId } }) => datasetInstanceId!);
        const deletingOpenInstance = savedInstanceIds.includes(datasetInstanceId);
        if (!deletingOpenInstance && instanceHasUpdated) {
            const isDraft = !!savedInstances.find(({ instance: { datasetInstanceId } }) => datasetInstanceId === datasetInstanceId)!.instance.isDraft;
            yield put(upsertDatasetInstanceStarted(isDraft));
        }
        const previousInstanceId: string = yield call(deleteSecondaryDocument, { documentId, parentDatasetId });
        const instanceId = deletingOpenInstance ? previousInstanceId : urlInstanceId;
        yield put(openDatasetInstanceById(instanceId, showLegacy));
        yield put(deleteSecondaryDocumentSuccessful());
        toast('Deleted successfully');
    } catch (e) {
        yield put(deleteSecondaryDocumentFailed((e as Error).message));
        toast.error('We encountered a problem when deleting your document. Please try again.');
    }
}

export function* attemptScrollToSection({ payload }: ReturnType<typeof scrollToSection>) {
    try {
        const { isOriginal, pageRef } = payload;
        const documentInView: SelectedDocument | null = yield select(getDocumentInView);
        if (!isNull(documentInView) && !isNull(pageRef)) {
            if (documentInView !== SelectedDocument.ORIGINAL && isOriginal) {
                yield put(toggleDocumentInView(SelectedDocument.ORIGINAL));
            }
            if (documentInView !== SelectedDocument.SECONDARY && !isOriginal) {
                yield put(toggleDocumentInView(SelectedDocument.SECONDARY));
            }
            yield put(setDocumentAnalysisSelectedPage(parseInt(pageRef)));
        }
    } catch (e) {
        toast.error('Failed to scroll to selected section');
    }
}

export function* attemptOpenRelevantSearchFieldSections({ payload }: ReturnType<typeof setSearchFieldSection>) {
    if (!isNull(payload)) {
        const savedInstances: Instance[] = yield select(getSavedInstances);
        const hierarchy: Hierarchy[] = yield select(getDatasetInstanceHierarchy);
        const currentOpenFieldsAndSections: OpenFieldSection[] = yield select(getOpenFieldsAndSections);
        const openFieldsAndSections = calculateOpenFieldSections(payload, savedInstances, hierarchy);

        if (currentOpenFieldsAndSections !== openFieldsAndSections) {
            yield put(expandAllDatasetFieldSections(uniq([...currentOpenFieldsAndSections, ...openFieldsAndSections])));
        }
    }
}

export function* attemptFindFuzzySearchResults({ payload }: ReturnType<typeof setFuzzyMatchSearchValue>) {
    const searchFields: SearchFieldSection[] = yield select(getAllSectionsAndFields);
    const searchFieldsFuse = new Fuse(searchFields, { keys: ['label', 'sectionLabel'], threshold: 0.2 });
    // Order fuzzy matches by their relevance and displaying matching sections first
    const fuzzyMatches = searchFieldsFuse.search(payload).map(({ item }) => item).sort((a, b) => b.type.localeCompare(a.type));
    yield put(setSearchFuzzyMatches(fuzzyMatches));
}

export function* attemptCalculateSearchFieldSections({ payload }: ReturnType<typeof calculateSearchFieldSections>) {
    const { instances, hierarchy, hiddenFields } = payload;
    const searchFields = getAllFlattenedSearchFields(instances, hierarchy, hiddenFields);
    yield put(setAllSearchFieldSections(searchFields));
}

function* openTimelineInstanceWatcher() {
    yield takeEvery(DatasetInstanceActionTypes.OPEN_TIMELINE_INSTANCE_STARTED, attemptOpenTimelineInstance);
}

function* openDatasetInstanceWatcher() {
    yield takeEvery(DatasetInstanceActionTypes.OPEN_DATASET_DEFINITION_STARTED, attemptOpenDatasetInstanceById);
}

function* setTimelineInstanceWatcher() {
    yield takeLeading(DatasetInstanceActionTypes.SET_TIMELINE_INSTANCE, attemptSetTimelineInstance);
}

function* toggleLegacyTimelineWatcher() {
    yield takeLeading(DatasetInstanceActionTypes.TOGGLE_DATASET_INSTANCE_SHOW_LEGACY, attemptToggleLegacyTimeline);
}

function* openTableDatasetInstanceWatcher() {
    yield takeEvery(DatasetInstanceActionTypes.OPEN_MODAL_DATASET_STARTED, attemptOpenTableDatasetInstance);
}

function* openDatasetInstanceByInstanceIdWatcher() {
    yield takeLeading(DatasetInstanceActionTypes.OPEN_DATASET_INSTANCE_BY_ID, attemptOpenDatasetInstanceByInstanceId);
}

function* upsertDatasetInstanceWatcher() {
    yield takeLeading(DatasetInstanceActionTypes.UPSERT_DATASET_INSTANCE_STARTED, saveAllInstances);
}

function* confirmMLDatasetInstanceWatcher() {
    yield takeLeading(DatasetInstanceActionTypes.CONFIRM_ML_DATASET_INSTANCE_STARTED, confirmMLDatasetInstance);
}

function* upsertModalDatasetInstanceWatcher() {
    yield takeLeading(DatasetInstanceActionTypes.UPSERT_MODAL_DATASET_STARTED, saveModalInstance);
}

function* fieldUpdatedWatcher() {
    yield debounce(1000, [
        DatasetInstanceActionTypes.UPDATE_FIELD_VALUE,
        DatasetInstanceActionTypes.UPDATE_GROUP_FIELD_VALUE,
        DatasetInstanceActionTypes.USER_CORRECT_AI_FIELD_VALUE,
        DatasetInstanceActionTypes.USER_CORRECT_AI_GROUP_FIELD_VALUE,
        DatasetInstanceActionTypes.UPDATE_CLAUSE_LABEL,
        DatasetInstanceActionTypes.UPDATE_GROUP_CLAUSE_LABEL,
        DatasetInstanceActionTypes.UPDATE_FIELD_LABEL,
        DatasetInstanceActionTypes.UPDATE_LINKED_ENTITIES,
        DatasetInstanceActionTypes.ADD_FIELD_CUSTOM_CHILD,
        DatasetInstanceActionTypes.UPDATE_FIELD_CUSTOM_CHILD,
        DatasetInstanceActionTypes.REMOVE_TABLE_DATASET_ROW,
        DatasetInstanceActionTypes.DUPLICATE_TABLE_DATASET_ROW
    ], checkAllInstancesUpdated);
}

function* modalFieldUpdatedWatcher() {
    yield debounce(1000, [DatasetInstanceActionTypes.UPDATE_MODAL_FIELD_VALUE, DatasetInstanceActionTypes.UPDATE_MODAL_GROUP_FIELD_VALUE, DatasetInstanceActionTypes.UPDATE_MODAL_CLAUSE_LABEL, DatasetInstanceActionTypes.UPDATE_MODAL_GROUP_CLAUSE_LABEL, DatasetInstanceActionTypes.ADD_MODAL_TABLE_DATASET_ROW, DatasetInstanceActionTypes.DUPLICATE_MODAL_DATASET_ROW], checkModalInstanceUpdated);
}

function* cancelInstanceWatcher() {
    yield takeEvery([DatasetInstanceActionTypes.UPDATE_DATASET_INSTANCE, DatasetInstanceActionTypes.EDIT_DATASET_INSTANCE], resetInstance);
}

function* fetchECSDatasetIdWatcher() {
    yield takeEvery(DatasetInstanceActionTypes.FETCH_ECS_DATASET_ID_STARTED, attemptFetchECSDatasetId);
}

function* generateECSTableWatcher() {
    yield takeEvery(DatasetInstanceActionTypes.GENERATE_NEW_ECS_TABLE_STARTED, attemptGenerateECSTable);
}

function* deleteSecondaryDocumentWatcher() {
    yield takeEvery(DatasetInstanceActionTypes.DELETE_SECONDARY_DOCUMENT_STARTED, attemptDeleteSecondaryDocument);
}

function* scrollToSectionWatcher() {
    yield takeEvery(DatasetInstanceActionTypes.SCROLL_TO_DATASET_INSTANCE_SECTION, attemptScrollToSection);
}

function* openRelevantSearchFieldsAndSectionsWatcher() {
    yield takeEvery(DatasetInstanceActionTypes.SET_DATASET_INSTANCE_SEARCH_FIELD_SECTION, attemptOpenRelevantSearchFieldSections);
}

function* updateFuzzySearchValueWatcher() {
    yield debounce(1000, DatasetInstanceActionTypes.SET_DATASET_INSTANCE_FUZZY_MATCH_SEARCH_VALUE, attemptFindFuzzySearchResults);
}

function* calculateSearchFieldSectionsWatcher() {
    yield takeEvery(DatasetInstanceActionTypes.CALCULATE_ALL_INSTANCE_SEARCH_FIELDS, attemptCalculateSearchFieldSections);
}

export function* datasetInstanceSaga() {
    yield all([
        fork(openDatasetInstanceWatcher),
        fork(openDatasetInstanceByInstanceIdWatcher),
        fork(setTimelineInstanceWatcher),
        fork(toggleLegacyTimelineWatcher),
        fork(openTableDatasetInstanceWatcher),
        fork(openTimelineInstanceWatcher),
        fork(upsertDatasetInstanceWatcher),
        fork(fieldUpdatedWatcher),
        fork(modalFieldUpdatedWatcher),
        fork(upsertModalDatasetInstanceWatcher),
        fork(cancelInstanceWatcher),
        fork(fetchECSDatasetIdWatcher),
        fork(generateECSTableWatcher),
        fork(deleteSecondaryDocumentWatcher),
        fork(confirmMLDatasetInstanceWatcher),
        fork(scrollToSectionWatcher),
        fork(openRelevantSearchFieldsAndSectionsWatcher),
        fork(updateFuzzySearchValueWatcher),
        fork(calculateSearchFieldSectionsWatcher)
    ]);
}
