import { all, call, debounce, fork, put, select, takeEvery, takeLeading } from 'redux-saga/effects';
import { isEqual, isNull } from 'lodash/fp';
import { push } from 'redux-first-history';

import { fetchAgreementTypeQueriesFailed, fetchAgreementTypeQueriesStarted, fetchAgreementTypeQueriesSuccessful, fetchAIAgreementTypesFailed, fetchAIAgreementTypesStarted, fetchAIAgreementTypesSuccessful, saveQueryOrderFailed, saveQueryOrderSuccessful, saveUpdatedQueriesFailed, saveUpdatedQueriesSuccessful, setDocumentQueriesUpdated, setLastUpdatedOrder, setSelectedAIAgreementType } from './actions';
import { AdminAIActionTypes, AIAgreementType, DocumentQuery, PortfolioTotal, SystemIdInfo, UpdatedQueryOrder } from './types';
import { fetchAgreementTypeQueries, fetchAIAgreementTypes, updateAgreementTypeQueries, updateAgreementTypeQueriesOrder } from '../../../../services/ai';
import { getSelectedAIAgreementTypeId, getUpdatedQueryOrder, getSavedDocumentQueries, getCurrentDocumentQueries, getUpdatedQueries, getSelectedClientId } from './selectors';

export function* attemptFetchAIAgreementTypes() {
    try {
        const clientId: number | null = yield select(getSelectedClientId);
        const { agreementTypes, portfolioTotal }: { agreementTypes: AIAgreementType[]; portfolioTotal: PortfolioTotal; } = yield call(fetchAIAgreementTypes, { clientId });
        yield put(fetchAIAgreementTypesSuccessful(agreementTypes, portfolioTotal));
    } catch (e) {
        yield put(fetchAIAgreementTypesFailed((e as Error).message));
    }
}

function* fetchAIAgreementTypesWatcher() {
    yield takeLeading(AdminAIActionTypes.FETCH_AI_AGREEMENT_TYPES_STARTED, attemptFetchAIAgreementTypes);
}

export function* updateAISelectedClient() {
    yield put(fetchAIAgreementTypesStarted());
    const selectedAgreementTypeId: number | null = yield select(getSelectedAIAgreementTypeId);
    if (!isNull(selectedAgreementTypeId)) {
        yield put(fetchAgreementTypeQueriesStarted(selectedAgreementTypeId, false));
    }
}

function* setAIQueriesSelectedClientWatcher() {
    yield takeLeading(AdminAIActionTypes.SET_AI_QUERIES_SELECTED_CLIENT, updateAISelectedClient);
}

export function* attemptFetchAgreementTypeQueries({ payload }: ReturnType<typeof fetchAgreementTypeQueriesStarted>) {
    try {
        const { agreementTypeId, shouldRedirect } = payload;
        yield put(setSelectedAIAgreementType(agreementTypeId, shouldRedirect));
        const clientId: number | null = yield select(getSelectedClientId);
        const { queries, systemInfo }: { queries: DocumentQuery[]; systemInfo: SystemIdInfo[]; } = yield call(fetchAgreementTypeQueries, { agreementTypeId, clientId });
        yield put(fetchAgreementTypeQueriesSuccessful(queries, systemInfo));
    } catch (e) {
        yield put(fetchAgreementTypeQueriesFailed((e as Error).message));
    }
}

function* fetchAgreementTypeQueriesWatcher() {
    yield takeEvery(AdminAIActionTypes.FETCH_AGREEMENT_TYPE_QUERIES_STARTED, attemptFetchAgreementTypeQueries);
}

export function* redirectAgreementTypeId({ payload }: ReturnType<typeof setSelectedAIAgreementType>) {
    const { shouldRedirect, agreementTypeId } = payload;
    if (shouldRedirect) {
        let navigationPath = '/admin/ai';
        if (!isNull(payload.agreementTypeId)) {
            navigationPath = `/admin/ai/${agreementTypeId}`;
        }
        yield put(push(navigationPath));
    }
}

function* redirectAIAgreementTypeWatcher() {
    yield takeEvery(AdminAIActionTypes.SET_SELECTED_AI_AGREEMENT_TYPE, redirectAgreementTypeId);
}

export function* attemptUpdateAgreementTypeQueriesOrder() {
    try {
        const agreementTypeId: number = yield select(getSelectedAIAgreementTypeId);
        const updatedQueryOrder: UpdatedQueryOrder[] = yield select(getUpdatedQueryOrder);
        const clientId: number | null = yield select(getSelectedClientId);
        const queries: DocumentQuery[] = yield call(updateAgreementTypeQueriesOrder, { updatedQueryOrder, agreementTypeId, clientId });
        yield put(saveQueryOrderSuccessful(queries));
    } catch (e) {
        yield put(saveQueryOrderFailed((e as Error).message));
    }
}

function* updateAgreementTypeQueriesOrderWatcher() {
    yield takeEvery(AdminAIActionTypes.SAVE_QUERY_ORDER_STARTED, attemptUpdateAgreementTypeQueriesOrder);
}

export function* checkDocumentQueriesUpdated() {
    const currentQueries: DocumentQuery[] = yield select(getCurrentDocumentQueries);
    const savedQueries: DocumentQuery[] = yield select(getSavedDocumentQueries);
    const haveUpdated = !isEqual(currentQueries, savedQueries);
    yield put(setDocumentQueriesUpdated(haveUpdated));
}

function* checkDocumentQueriesUpdatedWatcher() {
    yield debounce(200, [AdminAIActionTypes.ADD_NEW_DOCUMENT_QUERY, AdminAIActionTypes.UPDATE_DOCUMENT_QUERY, AdminAIActionTypes.DELETE_DOCUMENT_QUERY, AdminAIActionTypes.UPDATE_DOCUMENT_QUERY_SYSTEM_INFO], checkDocumentQueriesUpdated);
}

export function* attemptUpdateAgreementTypeQueries() {
    try {
        const agreementTypeId: number = yield select(getSelectedAIAgreementTypeId);
        const updatedQueries: DocumentQuery[] = yield select(getUpdatedQueries);
        const clientId: number | null = yield select(getSelectedClientId);
        const { agreementTypes, portfolioTotal, queries }: { agreementTypes: AIAgreementType[]; portfolioTotal: PortfolioTotal; queries: DocumentQuery[]; } = yield call(updateAgreementTypeQueries, { updatedQueries, agreementTypeId, clientId });
        yield put(saveUpdatedQueriesSuccessful(agreementTypes, portfolioTotal, queries));
        const lastUpdatedOrder = updatedQueries.length > 0 ? updatedQueries[0].queryOrder : null;
        yield put(setLastUpdatedOrder(lastUpdatedOrder));
    } catch (e) {
        yield put(saveUpdatedQueriesFailed((e as Error).message));
    }
}

function* updateAgreementTypeQueriesWatcher() {
    yield takeEvery(AdminAIActionTypes.SAVE_UPDATED_QUERIES_STARTED, attemptUpdateAgreementTypeQueries);
}

export function* adminAISaga() {
    yield all([
        fork(fetchAIAgreementTypesWatcher),
        fork(fetchAgreementTypeQueriesWatcher),
        fork(redirectAIAgreementTypeWatcher),
        fork(updateAgreementTypeQueriesOrderWatcher),
        fork(checkDocumentQueriesUpdatedWatcher),
        fork(updateAgreementTypeQueriesWatcher),
        fork(setAIQueriesSelectedClientWatcher)
    ]);
}
