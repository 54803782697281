import React, { useCallback, useState, MouseEvent, useMemo } from 'react';
import { isNull, noop } from 'lodash/fp';

import { AnalysisView } from '../../documents/my-documents/store';
import { AnalysisViewButtons } from './AnalysisViewButtons';
import { IconButton } from '../button/IconButton';
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { getUserHasFeaturePermission } from '../../auth/login/store';
import styles from './Analysis.module.scss';
import { Add, Robot, Calculator, Search, User, SmileyNeutral } from '../icons';
import { Position } from '../modal/PositionModal';
import { Action, ActionModal } from '../modal/ActionModal';
import { EntityModalType, getModalOpen as getEntityModalOpen, searchEntitiesStarted, toggleModal, upsertEntityStarted } from '../../admin/entity/store';
import { EntityModal as CreateEntityModal } from '../../admin/entity/EntityModal';
import { SearchEntityModal } from '../../admin/entity/SearchEntityModal';
import { FeaturePermission } from '../../admin/users/store';
import { toggleCalculatorHelpModal, toggleRiskToleranceHelpModal } from '../../admin/dataset-builder/store';
import { CalculatorHelpModal } from '../calculator/CalculatorHelpModal';
import { getRiskToleranceForDefinitions } from '../../datasets/instances/store';
import { RiskToleranceHelpModal } from '../risk-tolerance/RiskToleranceHelpModal';

const { primary, white } = styles;

interface AnalysisSideMenuProps {
    toggleAnalysisView: (view: AnalysisView) => void;
    currentView: AnalysisView;
    isPermittedToChangeView?: boolean;
    toggleMLModal?: () => void;
    showMLDataOption?: boolean;
    mlDataModalDisabled?: boolean;
}

export const AnalysisSideMenu: React.FC<AnalysisSideMenuProps> = ({ toggleAnalysisView, currentView, isPermittedToChangeView = true, toggleMLModal = noop, showMLDataOption, mlDataModalDisabled = false }) => {
    const [entityModalPosition, setEntityModalPosition] = useState<Position | null>(null);

    const dispatch = useAppDispatch();
    const entityModalOpen = useAppSelector(getEntityModalOpen);
    const hasEntityManagementPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.ENTITY_MANAGEMENT]));

    const riskTolerance = useAppSelector(getRiskToleranceForDefinitions);
    const showRiskFieldIndicator = riskTolerance.length > 0;

    const openEntityActionModal = useCallback((e: MouseEvent<HTMLButtonElement>) => setEntityModalPosition({ x: e.clientX, y: e.clientY }), []);
    const closeEntityActionModal = () => setEntityModalPosition(null);

    const toggleEntityModal = useCallback((value: EntityModalType | null) => dispatch(toggleModal(value)), [dispatch]);
    const openCreateEntityModal = useCallback(() => { toggleEntityModal(EntityModalType.CREATE); }, [toggleEntityModal]);
    const openSearchEntityModal = useCallback(() => { toggleEntityModal(EntityModalType.SEARCH); }, [toggleEntityModal]);
    const closeEntityModal = useCallback(() => toggleEntityModal(null), [toggleEntityModal]);

    const uploadEntity = useCallback(() => dispatch(upsertEntityStarted()), [dispatch]);
    const searchEntities = useCallback(() => dispatch(searchEntitiesStarted()), [dispatch]);

    const toggleCalculatorHelp = useCallback(() => dispatch(toggleCalculatorHelpModal()), [dispatch]);

    const toggleRiskToleranceHelp = useCallback(() => dispatch(toggleRiskToleranceHelpModal()), [dispatch]);

    const entityActions: Action[] = useMemo(() => [
        { label: 'Add Entity', icon: Add, onClick: openCreateEntityModal },
        { label: 'LEI Search', icon: Search, onClick: openSearchEntityModal }
    ], [openCreateEntityModal, openSearchEntityModal]);

    return (
        <>
            <div className={styles.sideMenuWrapper}>
                <AnalysisViewButtons toggleAnalysisView={toggleAnalysisView} currentView={currentView} disabled={!isPermittedToChangeView} />
                {hasEntityManagementPermission &&
                    <div className={styles.sectionWrapper}>
                        <div className={styles.divider} />
                        <IconButton icon={User} onClick={openEntityActionModal} fontSize={30} />
                    </div>
                }
                {showMLDataOption &&
                    <div className={styles.sectionWrapper}>
                        <div className={styles.divider} />
                        <IconButton icon={Robot} onClick={toggleMLModal} fontSize={30} overlayText='View AI Data' disabled={mlDataModalDisabled} />
                    </div>
                }
                <div className={styles.sectionWrapper}>
                    <div className={styles.divider} />
                    <IconButton icon={Calculator} onClick={toggleCalculatorHelp} fontSize={30} />
                </div>
                {showRiskFieldIndicator &&
                    <div className={styles.sectionWrapper}>
                        <div className={styles.divider} />
                        <IconButton icon={SmileyNeutral} onClick={toggleRiskToleranceHelp} fontSize={30} color={primary} iconFill={white} />
                    </div>
                }
            </div>
            <ActionModal
                isOpen={!isNull(entityModalPosition)}
                actions={entityActions}
                closeModal={closeEntityActionModal}
                position={entityModalPosition}
                testId='analysis-entity'
            />
            <CreateEntityModal
                uploadEntity={uploadEntity}
                closeModal={closeEntityModal}
                isOpen={entityModalOpen === EntityModalType.CREATE}
            />
            <SearchEntityModal
                searchEntities={searchEntities}
                closeModal={closeEntityModal}
                isOpen={entityModalOpen === EntityModalType.SEARCH}
            />
            <CalculatorHelpModal />
            <RiskToleranceHelpModal />
        </>
    );
};
