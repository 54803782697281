import React, { MouseEvent } from 'react';
import classnames from 'classnames';

import styles from './Button.module.scss';
import { Icon as ArkIcon } from '../icon/Icon';
import { CustomTooltip } from '../tooltip';

interface IconButtonProps {
    onClick: (event: MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    icon: React.FC;
    fontSize?: number;
    testId?: string;
    color?: string;
    withBorder?: boolean;
    withBackground?: boolean;
    iconFill?: string;
    margin?: string;
    showIndicator?: boolean;
    overlayText?: string | null;
    squareBorder?: boolean;
}

export const IconButton: React.FC<IconButtonProps> = ({
    onClick,
    disabled = false,
    fontSize = 28,
    icon,
    testId = '',
    color,
    withBorder = false,
    withBackground = false,
    iconFill,
    margin,
    showIndicator = false,
    overlayText = null,
    squareBorder
}) => (
    <CustomTooltip overlayText={overlayText}>
        <button
            onClick={onClick}
            className={classnames(styles.iconButton, { [styles.iconButtonWithBorder]: withBorder, [styles.iconButtonWithBackground]: withBackground, [styles.iconButtonSquareBorder]: squareBorder })}
            style={{ margin, position: showIndicator ? 'relative' : undefined }}
            disabled={disabled}
            data-testid={`icon-button-${testId}`}
        >
            <ArkIcon color={color} icon={icon} fontSize={fontSize} fill={iconFill} />
            {showIndicator && <div className={styles.indicator} style={{ left: `${(2 * fontSize) / 3}px`, height: `${fontSize / 4}px`, width: `${fontSize / 4}px`, top: 0 }} />}
        </button>
    </CustomTooltip>
);
