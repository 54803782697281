import { isNull, isUndefined } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { AgreementType, CDMDocumentNameId, fetchAllDocumentGroupsStarted, fetchAvailableDocumentNamesStarted, fetchMLReadyAgreementTypeIdsStarted, getAllClientDocumentGroups, getAvailableDocumentNames } from '../../admin/documents/store';
import { fetchAllEntitiesStarted, getBaseEntityDropdownOptions } from '../../admin/entity/store';
import { ClientFeaturePermission, FeaturePermission } from '../../admin/users/store';
import { getClientHasFeaturePermission, getUserHasFeaturePermission } from '../../auth/login/store';
import { Analytics, Delete, Document, Download, Export, Link, Pencil, Robot, SaveFile } from '../../shared/icons';
import { Action } from '../../shared/modal/ActionModal';
import { TableFilterType } from '../../shared/modal/TableFilterModal';
import { ArkTable, ArkTableColumn, ColumnSort } from '../../shared/table/ArkTable';
import { dateFormatter } from '../../shared/table/arkTableFormatters';
import { DeleteDocumentConfirmationModal } from './DeleteDocumentConfirmationModal';
import { ISDADocumentsModal } from './ISDADocumentsModal';
import { RenameDocumentModal } from './RenameDocumentModal';
import { ArkDocument, LinkedDocument, clearOriginalDocumentTableFilters, downloadAllDocumentsStarted, downloadDocumentStarted, exportToCDMStarted, exportToCSVStarted, fetchDocumentsStarted, getDeleteDocumentModalOpen, getIsLoading, getLinkedDocumentsModalOpen, getOriginalDocumentColumnSort, getOriginalDocumentFilters, getOriginalDocumentPageSize, getOriginalDocumentsPageNumber, getOriginalTableDocuments, getRenameDocumentModalOpen, getTotalOriginalDocuments, isLinkAgreementType, linkDocumentStarted, openDocumentStarted, originalDocumentsPaginationNext, originalDocumentsPaginationPrevious, reanalyzeDocumentStarted, runLatestMLQueriesStarted, setOriginalDocumentTableColumnSort, setOriginalDocumentTableFilters, setOriginalDocumentsPageSize, toggleDeleteConfirmationModal, toggleLinkDocumentsModal, toggleRenameDocumentModal, updateDocumentCustomFilters, getDocumentCustomFilterHasUpdated, getSelectedCustomFilterName, getSelectedDocumentCustomFilterId } from './store';

export const OriginalDocumentsTable: React.FC = () => {
    const dispatch = useAppDispatch();
    useFetchStarted([fetchDocumentsStarted(), fetchDocumentsStarted(1, AgreementType.ISDA), fetchAvailableDocumentNamesStarted(), fetchAllEntitiesStarted(true), fetchAllDocumentGroupsStarted(), fetchMLReadyAgreementTypeIdsStarted()]);
    const tableDocuments = useAppSelector(getOriginalTableDocuments);
    const isLoading = useAppSelector(getIsLoading);
    const allDocumentNames = useAppSelector(getAvailableDocumentNames);
    const allClientDocumentGroups = useAppSelector(getAllClientDocumentGroups);
    const entityOptions = useAppSelector(getBaseEntityDropdownOptions);

    const hasRAFExportPermission = useAppSelector(getClientHasFeaturePermission([ClientFeaturePermission.EXPORT_RAF_DOCUMENTS_TO_CSV]));
    const hasDocumentExtractAIPermission = useAppSelector(getClientHasFeaturePermission([ClientFeaturePermission.DOCUMENT_EXTRACT_WITH_AI]));
    const linkedDocumentsModalOpen = useAppSelector(getLinkedDocumentsModalOpen);
    const hasDownloadPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.DOWNLOAD_DOCUMENTS]));
    const hasDeletePermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.DELETE_DOCUMENTS]));
    const hasCompleteDocumentPermission = useAppSelector(getUserHasFeaturePermission([FeaturePermission.COMPLETE_DOCUMENT_DETAILS]));

    const deleteDocumentModalOpen = useAppSelector(getDeleteDocumentModalOpen);
    const renameDocumentModalOpen = useAppSelector(getRenameDocumentModalOpen);

    const pageNumber = useAppSelector(getOriginalDocumentsPageNumber);
    const totalDocuments = useAppSelector(getTotalOriginalDocuments);
    const documentFilters = useAppSelector(getOriginalDocumentFilters);
    const columnSort = useAppSelector(getOriginalDocumentColumnSort);
    const pageSize = useAppSelector(getOriginalDocumentPageSize);
    const documentFilterUpdated = useAppSelector(getDocumentCustomFilterHasUpdated);
    const selectedFilterId = useAppSelector(getSelectedDocumentCustomFilterId);
    const selectedFilterName = useAppSelector(getSelectedCustomFilterName);
    const nextPage = useCallback(() => { dispatch(originalDocumentsPaginationNext()); }, [dispatch]);
    const previousPage = useCallback(() => { dispatch(originalDocumentsPaginationPrevious()); }, [dispatch]);
    const updateFilter = useCallback((key: string, value: string | string[] | null, type: keyof TableFilterType = 'text') => { dispatch(setOriginalDocumentTableFilters(key, value, type)); }, [dispatch]);
    const clearAllFilters = useCallback(() => { dispatch(clearOriginalDocumentTableFilters()); }, [dispatch]);
    const updatePageSize = useCallback((pageSize: number) => { dispatch(setOriginalDocumentsPageSize(pageSize)); }, [dispatch]);

    const openDocument = useCallback(({ documentId, location, mimeType }: ArkDocument) => { dispatch(openDocumentStarted(location, documentId, mimeType)); }, [dispatch]);

    const deleteDocument = useCallback((document: ArkDocument) => { dispatch(toggleDeleteConfirmationModal(document)); }, [dispatch]);

    const saveDocument = useCallback(({ location, mimeType, documentDescription }: ArkDocument) => { dispatch(downloadDocumentStarted(location, mimeType, documentDescription)); }, [dispatch]);

    const saveAllDocuments = useCallback(({ allDocuments, documentDescription }: ArkDocument) => { dispatch(downloadAllDocumentsStarted(allDocuments.map(({ location }) => location), documentDescription)); }, [dispatch]);

    const openLinkDocumentsModal = useCallback(({ documentId }: ArkDocument) => { dispatch(toggleLinkDocumentsModal(documentId)); }, [dispatch]);

    const exportToCSV = useCallback(({ documentNameId, documentId, documentDescription }: ArkDocument) => { dispatch(exportToCSVStarted(documentNameId!, documentId, documentDescription)); }, [dispatch]);

    const exportToCDM = useCallback((documentId: number) => { dispatch(exportToCDMStarted(documentId, true)); }, [dispatch]);

    const reanalyzeDocument = useCallback((location: string) => { dispatch(reanalyzeDocumentStarted(location)); }, [dispatch]);

    const openDocumentRenameModal = useCallback((document: ArkDocument) => { dispatch(toggleRenameDocumentModal(document)); }, [dispatch]);

    const runLatestMLQueries = useCallback((documentId: number) => { dispatch(runLatestMLQueriesStarted(documentId)); }, [dispatch]);

    const getLatestMLAvailable = useCallback((document: ArkDocument) => !isUndefined(document.latestMlDataVersion) && !isUndefined(document.mlDataVersion) && !document.mlInProgress && hasDocumentExtractAIPermission && document.latestMlDataVersion > document.mlDataVersion, [hasDocumentExtractAIPermission]);

    const documentActions = useCallback((data: ArkDocument): Action[] => {
        const { agreementTypeId, linkedDocumentId, allDocuments, analysisComplete, analysisStarted, location, documentId, documentNameId } = data;
        const showLatestMLAvailable = getLatestMLAvailable(data);
        let options: Action[] = [
            { label: 'Open', icon: Document, onClick: () => openDocument(data) }
        ];
        if (hasDownloadPermission) {
            options.push(
                { label: 'Download', icon: SaveFile, onClick: () => saveDocument(data) },
                { label: `Download All (${allDocuments.length})`, icon: Download, onClick: () => saveAllDocuments(data) }
            );
        }
        if (!analysisComplete && analysisStarted === 0) {
            options.splice(1, 0, { label: 'Analyze', icon: Analytics, onClick: () => reanalyzeDocument(location) });
        }
        if (!isNull(agreementTypeId) && isLinkAgreementType(agreementTypeId) && !linkedDocumentId) {
            options.splice(3, 0, { label: 'Link to ISDA', icon: Link, onClick: () => openLinkDocumentsModal(data) });
        }
        if (agreementTypeId === AgreementType.RAF_REVIEW && hasRAFExportPermission) {
            options.splice(3, 0, { label: 'Export to CSV', icon: Export, onClick: () => exportToCSV(data) });
        }
        if (documentNameId && Object.values(CDMDocumentNameId).includes(documentNameId)) {
            options.splice(3, 0, { label: 'Export to CDM model', icon: Export, onClick: () => exportToCDM(documentId) });
        }
        if (showLatestMLAvailable) {
            options.push({ label: 'Run Latest AI', icon: Robot, onClick: () => runLatestMLQueries(documentId) });
        }
        if (hasCompleteDocumentPermission) {
            options.push({ label: 'Rename', icon: Pencil, onClick: () => openDocumentRenameModal(data), withSeparator: hasDeletePermission });
        }
        if (hasDeletePermission) {
            options.push({ label: 'Delete', icon: Delete, onClick: () => deleteDocument(data), isDelete: true });
        }
        return options;
    }, [deleteDocument, openDocument, openLinkDocumentsModal, reanalyzeDocument, saveAllDocuments, saveDocument, exportToCSV, openDocumentRenameModal, hasRAFExportPermission, hasCompleteDocumentPermission, hasDeletePermission, hasDownloadPermission, getLatestMLAvailable, runLatestMLQueries, exportToCDM]);

    const amendmentDocumentsFormatter = (documents: LinkedDocument[]) => documents.map(({ documentDescription }) => documentDescription);

    const columnDefs: ArkTableColumn[] = [
        { id: 'documentDescription', header: 'Document Description', field: 'documentDescription', width: 0.25, component: 'tooltip', canFilter: true, canSort: true },
        { id: 'documentName', header: 'Document', field: 'documentName', width: 0.2, component: 'tooltip', canFilter: true, canSort: true },
        { id: 'executedDate', header: 'Date of Execution', field: 'executedDate', width: 0.1, valueFormatter: dateFormatter, canSort: true },
        { id: 'entityA', header: 'Party A', field: 'entityA', width: 0.13, component: 'entity', canFilter: true, canSort: true },
        { id: 'entityB', header: 'Party B', field: 'entityB', width: 0.13, component: 'entity', canFilter: true, canSort: true },
        { id: 'allDocuments', header: 'All', field: 'allDocuments', width: 0.05, component: 'tooltipList', icon: Document, valueFormatter: amendmentDocumentsFormatter },
        { id: 'agency', header: 'Agency', field: 'isAgency', width: 0.05, component: 'tick' },
        { id: 'instanceComplete', header: 'Completed', field: 'instanceComplete', width: 0.05, component: 'tick' },
        { id: 'actions', header: '', field: '', width: 0.04, component: 'action', actions: documentActions }
    ];

    const selectedDocumentId = useMemo(() => {
        if (isNull(linkedDocumentsModalOpen)) {
            return null;
        }
        return tableDocuments.find(({ documentId }) => documentId === linkedDocumentsModalOpen)?.linkedDocumentId || null;
    }, [linkedDocumentsModalOpen, tableDocuments]);

    const closeLinkDocumentsModal = useCallback(() => { dispatch(toggleLinkDocumentsModal(null)); }, [dispatch]);

    const linkDocumentToOriginal = useCallback((documentId: number) => {
        dispatch(linkDocumentStarted(linkedDocumentsModalOpen!, documentId));
        closeLinkDocumentsModal();
    }, [dispatch, closeLinkDocumentsModal, linkedDocumentsModalOpen]);

    const toggleColumnSort = useCallback((columnSort: ColumnSort) => { dispatch(setOriginalDocumentTableColumnSort(columnSort)); }, [dispatch]);

    const documentNameOptions = useMemo(() => allDocumentNames
        .filter(({ datasetId }) => datasetId)
        .map(({ documentName }) => ({ value: documentName, label: documentName })), [allDocumentNames]
    );
    const clientDocumentGroupOptions = useMemo(() => allClientDocumentGroups.map(({ groupName, clientDocumentGroupId }, i) => ({ value: clientDocumentGroupId!.toString(), label: groupName, showDivider: i === allClientDocumentGroups.length - 1 })), [allClientDocumentGroups]);

    const documentsAndDocumentGroupOptions = useMemo(() => [...clientDocumentGroupOptions, ...documentNameOptions], [documentNameOptions, clientDocumentGroupOptions]);

    const filterDropdownOptions = useMemo(() => ({
        documentName: documentsAndDocumentGroupOptions,
        entityA: entityOptions,
        entityB: entityOptions
    }), [documentsAndDocumentGroupOptions, entityOptions]);

    const saveCustomFilters = useCallback((label: string, createNew: boolean) => dispatch(updateDocumentCustomFilters(label, createNew)), [dispatch]);

    return (
        <>
            <ArkTable
                colDefs={columnDefs}
                rowData={tableDocuments}
                testId='original-documents'
                isLoading={isLoading}
                page={pageNumber}
                total={totalDocuments}
                next={nextPage}
                previous={previousPage}
                filters={documentFilters}
                updateFilter={updateFilter}
                clearAllFilters={clearAllFilters}
                onRowDoubleClicked={row => openDocument(row as ArkDocument)}
                columnSort={columnSort}
                toggleSort={toggleColumnSort}
                pageSize={pageSize}
                updatePageSize={updatePageSize}
                filterDropdownOptions={filterDropdownOptions}
                saveCustomFilters={saveCustomFilters}
                saveButtonIsVisible={documentFilterUpdated}
                customFilterName={selectedFilterName}
                isExistingCustomFilter={!isNull(selectedFilterId)}
            />
            {!isNull(linkedDocumentsModalOpen) &&
                <ISDADocumentsModal
                    select={linkDocumentToOriginal}
                    close={closeLinkDocumentsModal}
                    selectedId={selectedDocumentId}
                />
            }
            <DeleteDocumentConfirmationModal isOpen={deleteDocumentModalOpen} />
            <RenameDocumentModal isOpen={renameDocumentModalOpen} isPermitted={hasCompleteDocumentPermission} />
        </>
    );
};
