import { getOr, isNull } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { useFetchStarted } from '../../../hooks/useFetchStarted';
import { NAV_BAR, ANALYSIS_MARGIN, useSplitView, PAGE_MARGIN_PADDING_BORDER, PAGE_VERTICAL_MARGIN_PADDING_BORDER } from '../../../hooks/useSplitView';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { AllInstancesWrapper } from '../../datasets/instances/AllInstancesWrapper';
import { fetchECSDatasetIdStarted, getDatasetInstance, getInstanceContainsMLData, getMLDataModalOpen, getMLDataUnconfirmed, removeAllFieldSections, toggleMLDataModal } from '../../datasets/instances/store';
import { Analysis, Tabs } from '../../shared/analysis/Analysis';
import { AnalysisSideMenu } from '../../shared/analysis/AnalysisSideMenu';
import { AnalysisView, getDocumentAnalysisView, getDocumentInView, getDocumentUrl, getIsOpening, getOriginalDocument, getSecondaryDocument, getSelectedSupportDocument, getSupportDocuments, openDocumentAndInstance, SelectedDocument, toggleDocumentInView, toggleDocumentAnalysisView, setDocumentAnalysisSelectedPage, getDocumentAnalysisSelectedPage, DocumentType } from '../my-documents/store';
import styles from './DocumentAnalysis.module.scss';
import { MLDataModal } from '../../datasets/instances/ml-modal/MLDataModal';
import { fetchMLReadyAgreementTypeIdsStarted } from '../../admin/documents/store';

interface AnalysisRouteParams {
    documentId: string | undefined;
    datasetInstanceId: string | undefined;
}

export const DocumentAnalysis: React.FC<RouteComponentProps<AnalysisRouteParams>> = ({ match }) => {
    const [currentDocumentId, setCurrentDocumentId] = useState<string>('');
    useFetchStarted([fetchECSDatasetIdStarted(), fetchMLReadyAgreementTypeIdsStarted()]);
    const [screenWidth, screenHeight] = useWindowResize();
    const datasetInstance = useAppSelector(getDatasetInstance);
    const originalDocument = useAppSelector(getOriginalDocument);
    const dispatch = useAppDispatch();
    const documentUrl = useAppSelector(getDocumentUrl);
    const secondaryDocument = useAppSelector(getSecondaryDocument);
    const supportDocument = useAppSelector(getSelectedSupportDocument);
    const documentInView = useAppSelector(getDocumentInView);
    const supportDocuments = useAppSelector(getSupportDocuments);
    const isOpening = useAppSelector(getIsOpening);
    const documentAnalysisView = useAppSelector(getDocumentAnalysisView);
    const mlDataModalOpen = useAppSelector(getMLDataModalOpen);
    const showMLDataOption = useAppSelector(getInstanceContainsMLData);
    const mlDataModalDisabled = useAppSelector(getMLDataUnconfirmed);
    const selectedPage = useAppSelector(getDocumentAnalysisSelectedPage);
    const [pageWidth, pageHeight] = useSplitView(documentAnalysisView, PAGE_MARGIN_PADDING_BORDER, PAGE_VERTICAL_MARGIN_PADDING_BORDER);
    const scheduleStartPage = useMemo(() => !isNull(originalDocument) && originalDocument.scheduleStartPage || 1, [originalDocument]);

    const selectedDocument = useMemo(() => {
        switch (documentInView) {
            case SelectedDocument.SUPPORT:
                return supportDocument;
            case SelectedDocument.SECONDARY:
                return secondaryDocument;
            case SelectedDocument.ORIGINAL:
            default:
                return originalDocument;

        }
    }, [documentInView, supportDocument, secondaryDocument, originalDocument]);

    const showScheduleStartPage = useMemo(() => documentInView === SelectedDocument.ORIGINAL, [documentInView]);

    const toggleSelectedDocument = useCallback((selectedDocument: SelectedDocument) => selectedDocument !== documentInView && dispatch(toggleDocumentInView(selectedDocument)), [dispatch, documentInView]);
    const toggleAnalysisView = useCallback((view: AnalysisView) => { view !== documentAnalysisView && dispatch(toggleDocumentAnalysisView(view)); }, [dispatch, documentAnalysisView]);
    const toggleMLModal = useCallback(() => { dispatch(toggleMLDataModal(!mlDataModalOpen)); }, [dispatch, mlDataModalOpen]);

    const setSelectedPage = useCallback((page: number | null) => dispatch(setDocumentAnalysisSelectedPage(page)), [dispatch]);

    const tabOptions = useMemo<Tabs[]>(() => {
        let tabs = [
            {
                title: getOr('', 'documentDescription', originalDocument),
                onClick: () => toggleSelectedDocument(SelectedDocument.ORIGINAL),
                selected: documentInView === SelectedDocument.ORIGINAL,
                type: originalDocument?.documentType || undefined
            },
            {
                title: getOr('', 'documentDescription', secondaryDocument),
                onClick: () => toggleSelectedDocument(SelectedDocument.SECONDARY),
                selected: documentInView === SelectedDocument.SECONDARY,
                disabled: !secondaryDocument,
                type: secondaryDocument?.documentType || undefined
            }
        ];

        if (supportDocuments.length) {
            tabs.push({
                title: `Support Documents (${supportDocuments.length})`,
                onClick: () => toggleSelectedDocument(SelectedDocument.SUPPORT),
                selected: documentInView === SelectedDocument.SUPPORT,
                type: DocumentType.SUPPORT
            });
        }

        return tabs;
    }, [toggleSelectedDocument, documentInView, originalDocument, secondaryDocument, supportDocuments]);

    const { params } = match;

    useEffect(() => {
        if ((!datasetInstance && !originalDocument && params.documentId && currentDocumentId === '') || ((params.documentId && currentDocumentId.length > 0) && params.documentId !== currentDocumentId)) {
            setCurrentDocumentId(params.documentId);
            dispatch(openDocumentAndInstance(params.documentId, params.datasetInstanceId));
        }
    }, [params, datasetInstance, dispatch, originalDocument, currentDocumentId]);

    useEffect(() => () => {
        dispatch(removeAllFieldSections());
    }, [dispatch]);

    const [width] = useSplitView(documentAnalysisView, PAGE_MARGIN_PADDING_BORDER + 2, PAGE_VERTICAL_MARGIN_PADDING_BORDER);

    if (!params.documentId) {
        return (
            <Redirect to='/documents' />
        );
    }

    const analysisWrapperDimensions = { height: `${screenHeight - NAV_BAR - ANALYSIS_MARGIN}px`, width: `${screenWidth}px` };
    const pageDimensions = { height: `${pageHeight}px`, width: `${pageWidth}px` };

    return (
        <div className={styles.analysisWrapper} style={analysisWrapperDimensions}>
            <AnalysisSideMenu toggleAnalysisView={toggleAnalysisView} currentView={documentAnalysisView} showMLDataOption={showMLDataOption} toggleMLModal={toggleMLModal} mlDataModalDisabled={mlDataModalDisabled} />
            <div className={styles.splitWrapper}>
                {documentAnalysisView !== AnalysisView.DATASET &&
                    <div className={styles.documentAnalysisWrapper} style={pageDimensions}>
                        <Analysis
                            selected={selectedDocument}
                            url={documentUrl}
                            documentInView={documentInView}
                            isOpening={isOpening}
                            tabOptions={tabOptions}
                            documentAnalysisView={documentAnalysisView}
                            selectedPage={selectedPage}
                            showScheduleStartPage={showScheduleStartPage}
                            scheduleStartPage={scheduleStartPage}
                            setSelectedPage={setSelectedPage}
                            width={width}
                        />
                    </div>
                }
                {documentAnalysisView !== AnalysisView.DOCUMENT &&
                    <div className={styles.datasetAnalysisWrapper} style={pageDimensions}>
                        <AllInstancesWrapper />
                    </div>
                }
            </div>
            <MLDataModal />
        </div>
    );
};
