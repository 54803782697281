import { flow, isNull, set } from 'lodash/fp';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import { CalculationBlock } from './CalculationBlock';
import { CalculatorInputs } from './CalculatorInputs';
import { Calculation, CalculationBuilder as CalculationBuilderType, CalculationCondition, CalculatorField, CalculatorInput, CalculatorInputType, CalculatorOptionType, CalculatorFieldType, symbolInputs, CalculationType, newCalculatorField } from './constants';
import styles from './Calculator.module.scss';
import { Scrollable } from '../scrollable/Scrollable';

const { lightGrey } = styles;

interface CalculatorProps {
    value: CalculatorField[];
    fieldType: CalculatorFieldType;
    showOutputField: boolean;
    showGracePeriod: boolean;
    includeFreeTextOption?: boolean;
    testId?: string;
    disabled?: boolean;
    updateValue: (output: CalculatorField[]) => void;
    isEditing: boolean;
    width: number;
    borderColor?: string;
    maxHeight?: number;
}

export const Calculator: React.FC<CalculatorProps> = ({
    value,
    fieldType,
    showOutputField,
    showGracePeriod,
    includeFreeTextOption = false,
    testId = '',
    disabled = false,
    isEditing = false,
    updateValue,
    width,
    borderColor = lightGrey,
    maxHeight = 426
}) => {
    const [selectedBuilderIndex, setSelectedBuilderIndex] = useState<number | null>(0);
    const [selectedCalculationBuilder, setSelectedCalculationBuilder] = useState<CalculationType>(CalculationType.OUTPUT);
    const [selectedCalculationIndex, setSelectedCalculationIndex] = useState<number>(0);
    const bottomRef = useRef<HTMLDivElement>(null);

    const selectedCalculation = useMemo(() => value[selectedCalculationIndex], [value, selectedCalculationIndex]);

    const updateSelectedCalculation = useCallback((key: keyof CalculatorField, fieldValue: CalculationCondition | null | CalculationBuilderType | Calculation[]) => {
        updateValue(value.map((calc, index) => index === selectedCalculationIndex ? { ...selectedCalculation, [key]: fieldValue } : calc));
    }, [updateValue, value, selectedCalculationIndex, selectedCalculation]);

    const getCalculationConditionValue = useCallback((index: number, outputLength: number) => {
        if (index === outputLength - 1) {
            return outputLength === 1 ? null : CalculationCondition.ELSE;
        }
        return index === 0 ? CalculationCondition.IF : CalculationCondition.ELSE_IF;
    }, []);

    const removeCalculation = useCallback((blockIndex: number) => {
        setSelectedCalculationIndex(0);
        setSelectedBuilderIndex(0);
        const blockRemoved = value.filter((_, index) => index !== blockIndex);
        const updatedOutput = blockRemoved.map((calc, index) => {
            const calculationCondition = getCalculationConditionValue(index, blockRemoved.length);
            if (calculationCondition === CalculationCondition.ELSE) {
                return flow(
                    set('calculationCondition', calculationCondition),
                    set('calculation', [{ index: 0, builder: [] }])
                )(calc);
            }
            return set('calculationCondition', calculationCondition, calc);
        });
        updateValue(updatedOutput);
    }, [updateValue, value, setSelectedBuilderIndex, setSelectedCalculationIndex, getCalculationConditionValue]);

    const addNewCalculation = useCallback(() => {
        const existingUpdated = value.map((calc, index) => set('calculationCondition', index === 0 ? CalculationCondition.IF : CalculationCondition.ELSE_IF, calc));
        const newOutput = [...existingUpdated, newCalculatorField(CalculationCondition.ELSE)];
        if (bottomRef && bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        updateValue(newOutput);
    }, [updateValue, value]);

    const addOption = useCallback((value: CalculatorInput) => {
        if (isNull(selectedBuilderIndex)) {
            updateSelectedCalculation(selectedCalculationBuilder, [...selectedCalculation[selectedCalculationBuilder], value]);
        } else {
            updateSelectedCalculation('calculation', selectedCalculation.calculation.map(row => row.index === selectedBuilderIndex ? set('builder', [...row.builder, value], row) : row));
        }
    }, [updateSelectedCalculation, selectedCalculation, selectedBuilderIndex, selectedCalculationBuilder]);

    const addNewBuilder = useCallback(() => {
        updateSelectedCalculation('calculation', [...selectedCalculation.calculation, { condition: null, builder: [], index: selectedCalculation.calculation.length }]);
        setSelectedBuilderIndex(selectedCalculation.calculation.length);
    }, [selectedCalculation, updateSelectedCalculation]);

    const updateTextField = useCallback((blockIndex: number, calculatorProperty: 'calculation' | 'output' | 'gracePeriod', itemIndex: number, text: string, builderIndex?: number) => {
        if (['output', 'gracePeriod'].includes(calculatorProperty)) {
            const updatedBuilder = (value[blockIndex][calculatorProperty] as CalculationBuilderType).map((val, index) => index === itemIndex ? set('value', text, val) : val);
            const updatedValue = value.map((calc, index) => index === blockIndex ? set(calculatorProperty, updatedBuilder, value[blockIndex]) : calc);
            updateValue(updatedValue);
        } else {
            const updatedBuilder = value[blockIndex].calculation[builderIndex!].builder.map((val, index) => index === itemIndex ? set('value', text, val) : val);
            const updatedValue = value.map((calc, index) => index === blockIndex ? set(`calculation[${builderIndex}].builder`, updatedBuilder, calc) : calc);
            updateValue(updatedValue);
        }
    }, [updateValue, value]);

    const getFontSize = useCallback((inputType: CalculatorInputType, type?: CalculatorOptionType, isInput?: boolean) => {
        if (symbolInputs.includes(inputType)) {
            return '17px';
        }
        if (isInput && inputType === CalculatorInputType.NAV_OBSERVATION_PERIOD) {
            return '11px';
        }
        if (isInput && (type === CalculatorOptionType.DROPDOWN || [CalculatorInputType.SHAREHOLDER_EQUITY, CalculatorInputType.ADDITIONAL_TERMINATION_EVENT, CalculatorInputType.OPERATIONAL_ADMINISTRATIVE_DEFAULT].includes(inputType))) {
            return '13px';
        }
        return '14px';
    }, []);

    const outputWrapperStyle = useMemo(() => isEditing && !disabled ? { width: 'calc(50% - 5px)', marginRight: '10px', maxHeight: `${maxHeight}px` } : { width: '100%', maxHeight: `${maxHeight}px` }, [isEditing, disabled, maxHeight]);
    return (
        <div className={styles.wrapper}>
            <div className={styles.calculatorWrapper} data-testid={`calculator-wrapper-${testId}`}>
                <div className={styles.outputRowsWrapper} style={outputWrapperStyle}>
                    <Scrollable>
                        {value.map(({ calculation, calculationCondition, output, gracePeriod }, calculationIndex) => (
                            <CalculationBlock
                                outputValue={value}
                                calculation={calculation}
                                output={output}
                                gracePeriod={gracePeriod}
                                calculationCondition={calculationCondition}
                                calculationIndex={calculationIndex}
                                getFontSize={getFontSize}
                                disabled={disabled}
                                isEditing={isEditing}
                                removeCalculation={removeCalculation}
                                selectedCalculation={selectedCalculation}
                                selectedCalculationIndex={selectedCalculationIndex}
                                selectedBuilderIndex={selectedBuilderIndex}
                                setSelectedBuilderIndex={setSelectedBuilderIndex}
                                setSelectedCalculationIndex={setSelectedCalculationIndex}
                                updateSelectedCalculation={updateSelectedCalculation}
                                updateTextField={updateTextField}
                                selectedCalculationBuilder={selectedCalculationBuilder}
                                setSelectedCalculationBuilder={setSelectedCalculationBuilder}
                                key={calculationIndex}
                                fieldType={fieldType}
                                borderColor={borderColor}
                                showOutputField={showOutputField}
                                showGracePeriod={showGracePeriod}
                            />
                        ))}
                    </Scrollable>
                </div>
                {isEditing && !disabled &&
                    <CalculatorInputs
                        fieldType={fieldType}
                        selectedCalculation={selectedCalculation}
                        selectedBuilderIndex={selectedBuilderIndex}
                        addOption={addOption}
                        addNewCalculation={addNewCalculation}
                        addNewBuilder={addNewBuilder}
                        getFontSize={getFontSize}
                        disabled={disabled}
                        width={width}
                        selectedCalculationBuilder={selectedCalculationBuilder}
                        maxHeight={maxHeight}
                        showOutputField={showOutputField}
                        includeFreeTextOption={includeFreeTextOption}
                    />
                }
            </div>
            <div ref={bottomRef} />
        </div>
    );
};
