import classnames from 'classnames';
import { getOr, isArray, isNull, isUndefined, uniqBy } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux';
import { ANALYSIS_MARGIN, DATASET_INSTANCE_HEADER, NAV_BAR, PAGE_MARGIN_PADDING_BORDER, useSplitView } from '../../../../hooks/useSplitView';
import { getDocumentAnalysisView, getIsOpening, getOriginalDocument, runLatestMLQueriesStarted, setDocumentAnalysisSelectedPage } from '../../../documents/my-documents/store';
import { ButtonResize } from '../../../shared/button/ButtonResize';
import { IconButton } from '../../../shared/button/IconButton';
import { Icon } from '../../../shared/icon/Icon';
import { ArrowLeft, ArrowRight, CaretDown, CaretUp, Delete, Repeat, Robot, SaveFile, Tick } from '../../../shared/icons';
import { ModalHeader } from '../../../shared/modal/ModalHeader';
import { Scrollable } from '../../../shared/scrollable/Scrollable';
import { IconTooltip, OverflowTooltip } from '../../../shared/tooltip';
import { WizardTab } from '../../../shared/wizard/WizardTab';
import { DatasetType, isGroupField } from '../../store';
import { InstanceIdentifier, MLDataValue, MLObject } from '../../store/mlTypes';
import { DatasetModal } from '../modal-instance/DatasetModal';
import { confirmMLDatasetInstanceStarted, DatasetInstanceSection, expandAllDatasetFieldSections, FormDatasetInstance, getCurrentInstances, getDatasetInstanceHierarchy, getInstanceUpdated, getIsLoading, getIsSaving, getMLData, getMLDataModalOpen, getOpenFieldsAndSections, getParentDatasetId, InstanceField, isDatasetLinkedField, isFormDatasetInstance, isGroupInstanceField, OpenField, OpenFieldSection, OpenFieldType, OpenSection, removeAllFieldSections, scrollToSection, toggleMLDataModal } from '../store';
import { CurrentInstanceValue } from './CurrentInstanceValue';
import { MLAnnexInstance } from './ml-annex-instance/MLAnnexInstance';
import { MLDatasetInstance } from './ml-dataset-instance/MLDatasetInstance';
import styles from './MLDataModal.module.scss';
import { Toggle } from '../../../shared/toggle';
import { getIsMLReadyAgreementType } from '../../../admin/documents/store';

const { green, red, yellowGreen, gold, amber, grey } = styles;

// CDEA Address Details Sort Order
const cdeaAddressKeys = ['partyATradingAddress', 'partyBTradingAddress', 'partyADocumentationAddress', 'partyBDocumentationAddress', 'partyANoticesAddress', 'partyBNoticesAddress'];
const potentialCdeaKeysOrder = ['Name', 'Name of Person', 'name', 'Address', 'address', 'FaxNo', 'Fax No', 'Fax No.', 'Fax', 'Fax Number', 'faxNo', 'fax', 'faxNumber', 'Email', 'E mail', 'E-mail', 'email'];

interface MLAnswerProps {
    answer: MLDataValue;
    depth: number;
    question?: string;
    includeDatasetField?: boolean;
    queryKey?: string;
}

export const MLAnswer: React.FC<MLAnswerProps> = ({ answer, depth, question, includeDatasetField, queryKey = '' }) => {
    const { color, icon } = useMemo(() => answer ? { icon: Tick, color: green } : { icon: Delete, color: red }, [answer]);

    const sortObjectKeys = useCallback((keyValuePairs: [string, MLDataValue][]) => {
        if (cdeaAddressKeys.includes(queryKey)) {
            return keyValuePairs.sort((a, b) => potentialCdeaKeysOrder.indexOf(a[0]) - potentialCdeaKeysOrder.indexOf(b[0]));
        }
        return keyValuePairs.sort((a, b) => a[0].localeCompare(b[0]));
    }, [queryKey]);

    const mlAnswerStyle = useMemo(() => {
        if (depth > 1) {
            return { width: '100%' };
        }
        if (!isUndefined(includeDatasetField)) {
            return includeDatasetField ? { width: 'calc(30% - 10px)', marginRight: '10px', justifyContent: 'center', alignItems: 'center' } : { width: '40%', alignItems: 'flex-end' };
        }
        return { width: 'fit-content', maxWidth: 'calc(100% - 87px)', marginRight: '10px' };
    }, [depth, includeDatasetField]);

    const aiAnswerLeftBlank = answer === '' && question && question.includes('leave this field blank');
    const returnNotFound = isUndefined(answer) || isNull(answer) || (answer === '' && question && !question.includes('leave this field blank'));

    if (returnNotFound) {
        return (
            <div className={classnames(styles.mlUnknownAnswer, { [styles.rightAlignText]: !includeDatasetField })} style={mlAnswerStyle}>
                <OverflowTooltip overlayText='Not Found by AI' />
            </div>
        );
    }

    if (aiAnswerLeftBlank) {
        return (
            <div className={classnames(styles.mlFieldLeftBlank, { [styles.rightAlignText]: !includeDatasetField })} style={mlAnswerStyle}>
                <OverflowTooltip overlayText='Left Blank by AI' />
            </div>
        );
    }

    if (typeof answer === 'boolean') {
        return (
            <div className={classnames(styles.mlDatasetAnswer, { [styles.rightAlignText]: !includeDatasetField })} style={mlAnswerStyle} >
                <Icon icon={icon} color={color} fontSize={20} />
            </div>
        );
    }

    if (['string', 'number'].includes(typeof answer)) {
        const overlayText = typeof answer === 'string' ? answer : (answer as number).toString();
        return (
            <div className={classnames(styles.mlDatasetAnswer, { [styles.rightAlignText]: !includeDatasetField })} style={mlAnswerStyle}>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <OverflowTooltip overlayText={overlayText} />
                </div>
            </div>
        );
    }

    if (isArray(answer)) {
        const isStringArray = typeof answer[0] === 'string';
        if (isStringArray) {
            return (
                <div className={classnames(styles.mlDatasetAnswer, { [styles.rightAlignText]: !includeDatasetField })} style={mlAnswerStyle}>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <OverflowTooltip overlayText={answer.join(', ')} />
                    </div>
                </div>
            );
        }

        return (
            <div className={styles.mlDatasetArrayObject} style={mlAnswerStyle}>
                {(answer as MLObject[]).map((row, i) => (
                    <div key={i} className={styles.mlDatasetObjectTable} style={{ width: '100%' }}>
                        {sortObjectKeys(Object.entries(row)).map(([key, value]) => (
                            <div className={styles.mlDatasetObjectRow} key={key}>
                                <div className={styles.mlDatasetObjectKey}><OverflowTooltip overlayText={key} /></div>
                                <div className={styles.mlDatasetObjectValue}>
                                    <Scrollable maxHeight='150px'>
                                        <MLAnswer answer={value} depth={depth + 1} question={question} includeDatasetField={includeDatasetField} queryKey={queryKey} />
                                    </Scrollable>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div className={styles.mlDatasetObjectTable} style={mlAnswerStyle}>
            {sortObjectKeys(Object.entries(answer as MLObject)).map(([key, value]) => (
                <div className={styles.mlDatasetObjectRow} key={key}>
                    <div className={styles.mlDatasetObjectKey}><OverflowTooltip overlayText={key} /></div>
                    <div className={styles.mlDatasetObjectValue}>
                        <Scrollable maxHeight='150px'>
                            <MLAnswer answer={value} depth={depth + 1} question={question} includeDatasetField={includeDatasetField} queryKey={queryKey} />
                        </Scrollable>
                    </div>
                </div>
            ))}
        </div>
    );
};

const MODAL_HEADER_HEIGHT = 56;
const MODAL_PADDING = 20;
const TITLE_MARGIN = 27;
const OPPORTUNITY_TEXT = 27;
const PREVIOUS_QUERIES = 32;
const WIZARD_TABS = 45;
const widthOffset = PAGE_MARGIN_PADDING_BORDER;
const BOTTOM_BUTTON = 50;
// This is when a systemId has not yet been assigned to the dataset
const MISSING_SYSTEM_ID_DATASET_ID = 0;

const enum AIAnswerView {
    INSTANCE = 'Data Map',
    LIST = 'Queries',
    AGENCY_ANNEX = 'Agency Annex'
}

export const MLDataModal: React.FC = () => {
    const [includePreviousQueries, setIncludePreviousQueries] = useState<boolean>(true);
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getMLDataModalOpen);
    const mlData = useAppSelector(getMLData);
    const hierarchy = useAppSelector(getDatasetInstanceHierarchy);
    const isOpeningDocument = useAppSelector(getIsOpening);
    const isLoadingInstance = useAppSelector(getIsLoading);
    const documentAnalysisView = useAppSelector(getDocumentAnalysisView);
    const isSaving = useAppSelector(getIsSaving);
    const instanceUpdated = useAppSelector(getInstanceUpdated);
    const originalDocument = useAppSelector(getOriginalDocument);
    const scheduleStartPage = useMemo(() => !isNull(originalDocument) && originalDocument.scheduleStartPage || 1, [originalDocument]);
    const isMLReadyAgreementType = useAppSelector(getIsMLReadyAgreementType(mlData?.agreementTypeId));
    const allInstances = useAppSelector(getCurrentInstances);

    const parentDatasetId = useAppSelector(getParentDatasetId);
    const parentInstance = useMemo(() => allInstances.map(({ instance }) => instance).find(({ datasetId }) => datasetId === parentDatasetId) || null, [allInstances, parentDatasetId]);

    const [AIView, setAIView] = useState<AIAnswerView>(AIAnswerView.LIST);
    const isInstanceView = useMemo(() => AIView === AIAnswerView.INSTANCE, [AIView]);
    const isListView = useMemo(() => AIView === AIAnswerView.LIST, [AIView]);
    const isAnnexView = useMemo(() => AIView === AIAnswerView.AGENCY_ANNEX, [AIView]);
    const changeView = useCallback((view: AIAnswerView) => setAIView(view), []);
    const goToInstanceView = useCallback(() => changeView(AIAnswerView.INSTANCE), [changeView]);

    const firstUnconfirmedQueryId = useMemo(() => {
        const key = mlData && mlData.instanceMLData.find(({ version }) => version > mlData.versionConfirmed)?.key || null;
        if (isNull(key)) {
            return null;
        }
        return `ml-query-${key}`;
    }, [mlData]);

    const scrollUnconfirmedIntoView = useCallback((firstUnconfirmedQueryId: string) => {
        const firstUnconfirmedQuery = document.getElementById(firstUnconfirmedQueryId);
        firstUnconfirmedQuery?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }, []);

    useEffect(() => {
        if (!isNull(firstUnconfirmedQueryId) && AIView === AIAnswerView.LIST) {
            setTimeout(() => scrollUnconfirmedIntoView(firstUnconfirmedQueryId), 400);
        }
    }, [firstUnconfirmedQueryId, AIView, scrollUnconfirmedIntoView]);

    const includeDatasetField = useMemo(() => !isNull(mlData) && isMLReadyAgreementType, [mlData, isMLReadyAgreementType]);

    const openFieldsAndSections = useAppSelector(getOpenFieldsAndSections);
    const heightOffset = useMemo(() => {
        let offset = NAV_BAR + MODAL_HEADER_HEIGHT + MODAL_PADDING + WIZARD_TABS;
        if (isListView) {
            offset += (TITLE_MARGIN + PREVIOUS_QUERIES);
            if (includeDatasetField) {
                offset += OPPORTUNITY_TEXT;
            }
        }
        return offset;
    }, [isListView, includeDatasetField]);

    const [width, height] = useSplitView(documentAnalysisView, widthOffset, heightOffset);

    const closeModal = () => dispatch(toggleMLDataModal(false));
    const confirm = () => dispatch(confirmMLDatasetInstanceStarted());
    const runLatestMLQueries = useCallback((documentId: number) => { dispatch(runLatestMLQueriesStarted(documentId)); }, [dispatch]);

    const awaitingUserConfirmation = useMemo(() => !isNull(mlData) && mlData.versionConfirmed < mlData.version, [mlData]);

    const containsAnnexData = useMemo(() => !isNull(mlData) && !isNull(mlData.extractedAnnexData), [mlData]);

    const questionStyle = useMemo(() => includeDatasetField ? { width: 'calc(35% - 10px)', marginRight: '10px', justifyContent: 'flex-start' } : { width: 'calc(60% - 10px)', marginRight: '10px', justifyContent: 'flex-start' }, [includeDatasetField]);
    const mlAnswerHeaderStyle = useMemo(() => includeDatasetField ? { width: 'calc(30% - 10px)', marginRight: '10px', justifyContent: 'center' } : { width: '40%', justifyContent: 'flex-end' }, [includeDatasetField]);

    const showRunLatest = useMemo(() => !isNull(mlData) && mlData.version < mlData.latestVersion, [mlData]);
    const updateDisabled = useMemo(() => !isNull(mlData) && (mlData.inProgress || isSaving), [mlData, isSaving]);
    const runLatestDisabled = useMemo(() => !isNull(mlData) && (instanceUpdated || mlData.inProgress || isSaving), [mlData, instanceUpdated, isSaving]);

    const openSectionsForMlQuestion = useCallback((mlData: InstanceIdentifier) => {
        const { datasetType, datasetId, systemId, parentFieldId, sectionId, rowId } = mlData;
        const mlDatasetId = datasetId.toString();
        let mlOpenFieldsAndSections: OpenFieldSection[] = [];
        // First add in the open section/field for the ml question
        if (!isUndefined(sectionId) && datasetType === DatasetType.FORM) {
            const openSection: OpenSection = { sectionId, datasetId: mlDatasetId, type: OpenFieldType.SECTION, parentFieldId: parentFieldId };
            mlOpenFieldsAndSections = [...mlOpenFieldsAndSections, openSection];
            let openField: OpenField = { sectionId: sectionId || '', groupIndex: undefined, datasetId: mlDatasetId, fieldId: '', type: OpenFieldType.FIELD, parentFieldId: parentFieldId };
            const fieldInstance = allInstances.find(({ instance }) => instance.datasetId === datasetId);
            if (!isUndefined(fieldInstance)) {
                const parentDataset = hierarchy.find(({ datasetId }) => datasetId === mlDatasetId)!;
                const parentInstance = allInstances.find(({ instance }) => instance.datasetId === parseInt(parentDataset.parentId!));
                if (!isUndefined(parentDataset)) {
                    // then its a dataset field and we want the parent field id
                    const openFieldTwo: OpenField = { sectionId: parentDataset.sectionId, groupIndex: undefined, datasetId: parentDataset.parentId || '', fieldId: parentDataset.fieldId, type: OpenFieldType.FIELD, parentFieldId: parentInstance?.parentFieldId || '' };
                    if (openFieldTwo.fieldId !== '') {
                        mlOpenFieldsAndSections = [...mlOpenFieldsAndSections, openFieldTwo];
                    }
                }
                // its either a single instance field or group field
                // get the id for the field - if it part of a group field then we use the fieldInstance.parentFieldId else we get the individual field id
                const fieldId = (fieldInstance.instance.datasetFields[sectionId] as InstanceField[]).map(field => {
                    if (isGroupField(field)) {
                        return fieldInstance.parentFieldId;
                    } else {
                        return field.systemId === systemId ? field.id! : '';
                    }
                });
                openField.fieldId = fieldId.filter(value => value !== '')[0];
                if (openField.fieldId !== '') {
                    mlOpenFieldsAndSections = [...mlOpenFieldsAndSections, openField];
                }
            }
        }
        if (!isUndefined(rowId) && datasetType === DatasetType.TABLE) {
            // If its a table field then we add the parent dataset field as an open field
            const parentDataset = hierarchy.find(({ datasetId }) => datasetId === mlDatasetId)!;
            const parentInstance = allInstances.find(({ instance }) => instance.datasetId === parseInt(parentDataset.parentId!))!;
            const openField: OpenField = { sectionId: parentDataset?.sectionId || '', groupIndex: undefined, datasetId: parentDataset?.parentId || '', fieldId: parentDataset?.fieldId || '', type: OpenFieldType.FIELD, parentFieldId: parentInstance?.parentFieldId || '' };
            mlOpenFieldsAndSections = [...mlOpenFieldsAndSections, openField];
        }
        // once we have the open field/section for the question field we then climb up the dataset hierarchy and open each section above.
        let parentDatasetId: string | undefined = mlDatasetId;
        while (!isUndefined(parentDatasetId)) {
            const parentDataset = hierarchy.find(({ datasetId }) => datasetId === parentDatasetId);
            if (!isUndefined(parentDataset)) {
                if (!isNull(parentDataset.parentId) && parentDataset.parentId !== '') {
                    // then we have not hit the top level so add this one to the list of open sections and move up the tree
                    const parentInstance = allInstances.find(({ instance }) => instance.datasetId === parseInt(parentDataset.parentId!));
                    if (!isUndefined(parentInstance)) {
                        const openSection: OpenSection = { sectionId: parentDataset!.sectionId || '', datasetId: parentInstance.instance.datasetId!.toString(), type: OpenFieldType.SECTION, parentFieldId: parentInstance.parentFieldId };
                        mlOpenFieldsAndSections.push(openSection);
                        // back to the hierarchy with the datasetId until we have a parent dataset that has no parent Id
                        parentDatasetId = parentInstance.instance.datasetId!.toString();
                        continue;
                    }
                }
                parentDatasetId = undefined;
            }
        }
        return mlOpenFieldsAndSections;
    }, [allInstances, hierarchy]);

    const mlOpenSections = useMemo(() => {
        if (!isNull(mlData)) {
            const currentVersionConfirmed = mlData?.versionConfirmed;
            const mlInstanceData = mlData?.instanceMLData;
            const uniqueLatestMLData = mlInstanceData.reduce((acc: InstanceIdentifier[], cur) => {
                const sectionAlreadyIncluded = !isUndefined(cur.sectionId) && acc.map(({ sectionId }) => sectionId).includes(cur.sectionId);
                if ((cur.version < currentVersionConfirmed) || sectionAlreadyIncluded || cur.datasetId === MISSING_SYSTEM_ID_DATASET_ID) {
                    return acc;
                }
                return [...acc, cur];
            }, []);
            const openMlSections = uniqueLatestMLData.reduce((acc: OpenFieldSection[], cur) => {
                const openSectionsForQuestion = openSectionsForMlQuestion(cur);
                return [...acc, ...openSectionsForQuestion];
            }, []);
            const openFields = openMlSections.filter(({ type }) => type === OpenFieldType.FIELD);
            const openSections = openMlSections.filter(({ type }) => type === OpenFieldType.SECTION);
            const allOpenFieldsAndSections = [...uniqBy('fieldId', openFields), ...uniqBy('sectionId', openSections)];
            return allOpenFieldsAndSections;
        }
        return [];
    }, [openSectionsForMlQuestion, mlData]);

    const expandAll = useCallback(() => dispatch(expandAllDatasetFieldSections(mlOpenSections)), [dispatch, mlOpenSections]);
    const collapseAllFieldsAndSections = useCallback(() => openFieldsAndSections.length === 0 ? expandAll() : dispatch(removeAllFieldSections()), [dispatch, openFieldsAndSections, expandAll]);

    const scrollToQuestion = useCallback((fieldId: string) => {
        const field = document.getElementById(`ml-dataset-instance-field-${fieldId}`);
        field?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }, []);

    const scrollToPdfPage = useCallback((field: InstanceIdentifier) => {
        let currentSectionId = field.sectionId;
        let currentDatasetId: number | undefined = field.datasetId;
        let sectionWithPageRef: DatasetInstanceSection | undefined = undefined;

        while (isUndefined(sectionWithPageRef) && !isUndefined(currentDatasetId)) {
            const instances = allInstances.map(({ instance }) => instance);
            const relevantInstance = instances.find(({ datasetId }) => datasetId === currentDatasetId);
            const isFormInstance = !isUndefined(relevantInstance) && isFormDatasetInstance(relevantInstance);
            if (isFormInstance) {
                const { datasetSections } = relevantInstance as FormDatasetInstance;
                const relevantSection = datasetSections.find(({ id }) => id === currentSectionId);
                // Find the relevant section based on, initially the sectionId found above in systemDatasetIdentifiers and recalculated as we work up the instance tree
                if (relevantSection) {
                    // If the section has a page ref then it is the relevant section so we update here
                    // else we climb the dataset hierarchy until we find a parent section with a pageRef
                    const pageRef = getOr(undefined, 'pageRef', relevantSection) || undefined;
                    if (pageRef) {
                        sectionWithPageRef = relevantSection as DatasetInstanceSection;
                    }
                }
            }
            // Find the parent dataset instance and attempt to find the sectionId that the child dataset is within
            // If we hit the parent level then the parentId will be '' or null
            // If a parent is a table and not a form then set the sectionId to be undefined and continue on the process with updated currentDatasetId
            const currentParentId = hierarchy.find(({ datasetId }) => datasetId === currentDatasetId!.toString())?.parentId;
            if (typeof currentParentId === 'string' && currentParentId.length > 0) {
                const relevantInstance = instances.find(({ datasetId }) => datasetId === parseInt(currentParentId));
                const isFormInstance = !isUndefined(relevantInstance) && isFormDatasetInstance(relevantInstance);
                if (isFormInstance) {
                    const { datasetFields } = relevantInstance as FormDatasetInstance;
                    const filteredSection = Object.entries(datasetFields).find(([, fields]) => !isUndefined(fields.find(field => !isGroupInstanceField(field) && isDatasetLinkedField(field) && field.settings.datasetLinked === currentDatasetId?.toString())));
                    currentSectionId = !isUndefined(filteredSection) ? filteredSection[0] : undefined;
                } else {
                    currentSectionId = undefined;
                }
                currentDatasetId = parseInt(currentParentId);
            } else {
                currentDatasetId = undefined;
            }
        }
        setTimeout(() => {
            if (!isUndefined(sectionWithPageRef)) {
                dispatch(scrollToSection(sectionWithPageRef));
            } else {
                dispatch(setDocumentAnalysisSelectedPage(scheduleStartPage));
            }
        }, 250);
    }, [hierarchy, allInstances, scheduleStartPage, dispatch]);

    const linkToInstance = useCallback((field: InstanceIdentifier) => {
        goToInstanceView();
        const allSectionsForQuestion = openSectionsForMlQuestion(field);
        dispatch(expandAllDatasetFieldSections(allSectionsForQuestion));
        setTimeout(() => scrollToQuestion(field.parentFieldId), 150);
        scrollToPdfPage(field);

    }, [goToInstanceView, openSectionsForMlQuestion, dispatch, scrollToQuestion, scrollToPdfPage]);

    const getFilteredQueries = useCallback((instanceMLData: InstanceIdentifier[]) => instanceMLData.filter(({ version }) => includePreviousQueries || (version > (mlData?.versionConfirmed || 0))), [mlData, includePreviousQueries]);

    const getQuestionIconColour = (percentage: number) => {
        const index = [19, 49, 59, 79, 100].findIndex(upperBound => percentage <= upperBound);
        return [red, amber, gold, yellowGreen, green][index];
    };

    const AIAnswerBody = useMemo(() => {
        if (isNull(mlData)) {
            return null;
        }
        if (isInstanceView) {
            return (
                <div className={styles.mlDatasetFindings} style={{ height: height }}>
                    <Scrollable maxHeight={`${height}px`}>
                        {parentInstance && <MLDatasetInstance datasetInstance={parentInstance} isEditing={awaitingUserConfirmation} isUpdating={false} parentDatasetIds={[]} parentFieldId='' instanceExecutedDateMatchesParent mlData={mlData} />}
                    </Scrollable>
                </div>
            );
        }
        if (isAnnexView) {
            return (
                <div className={styles.mlDatasetFindings} style={{ height: height }}>
                    <Scrollable maxHeight={`${height}px`}>
                        {parentInstance && <MLAnnexInstance datasetInstance={parentInstance} isEditing={awaitingUserConfirmation} isUpdating={false} parentDatasetIds={[]} parentFieldId='' instanceExecutedDateMatchesParent extractedAnnexData={mlData.extractedAnnexData!} />}
                    </Scrollable>
                </div>
            );
        }
        const filteredQueries = getFilteredQueries(mlData.instanceMLData);
        return (
            <div style={{ height }}>
                <div className={styles.mlDatasetTableHeader}>
                    <div className={styles.headerCell} style={questionStyle}>Question</div>
                    <div className={styles.headerCell} style={mlAnswerHeaderStyle}>AI Answer</div>
                    {includeDatasetField && <div className={styles.headerCell} style={{ width: '30%', justifyContent: 'center' }}>Ark51 Value</div>}
                    <div className={styles.headerCell} style={{ width: '5%', justifyContent: 'center' }}></div>
                </div>
                <div className={styles.mlDatasetFindings} style={{ height: height - BOTTOM_BUTTON }}>
                    <Scrollable maxHeight={`${height - BOTTOM_BUTTON}px`}>
                        {filteredQueries.map(field => {
                            const { key, question, mlValue, datasetId, totalQueries, correctQueries, percentage } = field;
                            const fieldUnknown = datasetId === MISSING_SYSTEM_ID_DATASET_ID;
                            const noTotalQueries = isUndefined(percentage) || !isUndefined(totalQueries) && totalQueries === 0;
                            const percentageColour = noTotalQueries ? grey : getQuestionIconColour(percentage!);
                            return (
                                <div key={key} className={styles.mlDatasetQueryWrapper} id={`ml-query-${field.key}`}>
                                    <IconTooltip content={`Success Rate: ${correctQueries} / ${totalQueries} (${percentage}%)`} icon={Robot} fontSize={35} iconColor={percentageColour} />
                                    <div className={styles.mlDatasetQuestion} style={questionStyle}>{question}</div>
                                    <MLAnswer answer={mlValue} includeDatasetField={includeDatasetField} depth={1} question={question} queryKey={key} />
                                    {includeDatasetField && <CurrentInstanceValue field={field} versionConfirmed={mlData.versionConfirmed} disabled={updateDisabled} question={question} mlValue={mlValue} />}
                                    {!fieldUnknown && <div className={styles.link}><IconButton icon={ArrowRight} onClick={() => linkToInstance(field)} /></div>}
                                </div>
                            );
                        })}
                    </Scrollable>
                </div>
            </div>
        );
    }, [isInstanceView, height, mlData, parentInstance, includeDatasetField, mlAnswerHeaderStyle, questionStyle, updateDisabled, linkToInstance, isAnnexView, awaitingUserConfirmation, getFilteredQueries]);

    const expandButtonLabel = useMemo(() => openFieldsAndSections.length === 0 ? 'Expand Latest' : 'Collapse All', [openFieldsAndSections]);
    const expandButtonIcon = useMemo(() => openFieldsAndSections.length === 0 ? CaretDown : CaretUp, [openFieldsAndSections]);

    const showIconButton = useMemo(() => {
        const additionalButtons = [showRunLatest, awaitingUserConfirmation].filter(isTrue => !!isTrue);
        const displayedButtonsWidth = 300;
        const allButtons = displayedButtonsWidth + (150 * additionalButtons.length);
        return allButtons > width;
    }, [showRunLatest, awaitingUserConfirmation, width]);

    const wizardTabs = useMemo(() => {
        let tabOptions = [AIAnswerView.LIST, AIAnswerView.INSTANCE];
        if (containsAnnexData) {
            tabOptions.push(AIAnswerView.AGENCY_ANNEX);
        }
        return tabOptions.map(tab => ({
            label: tab,
            selected: tab === AIView,
            onClick: () => changeView(tab),
            disabled: false
        }));
    }, [containsAnnexData, AIView, changeView]);

    if (isNull(mlData) || isOpeningDocument || isLoadingInstance) {
        return null;
    }

    return (
        <DatasetModal
            isOpen={isOpen}
            closeModal={closeModal}
            topOffset={`${DATASET_INSTANCE_HEADER + NAV_BAR + ANALYSIS_MARGIN}px`}
        >
            <div className={styles.mlDatasetWrapper} data-testid='ml-dataset-wrapper' style={{ width }}>
                <ModalHeader label='AI Data Extracted' icon={Robot} />
                <div className={styles.tabWrapper}>
                    {wizardTabs.map(({ label, onClick, selected, disabled }) => (
                        <WizardTab key={label} label={label} onClick={onClick} selected={selected} disabled={disabled} testId={`'ml-dataset-${label}`} />
                    ))}
                </div>
                <div className={styles.mlDatasetContent}>
                    {isListView && <OverflowTooltip className={styles.mlDatasetTitle} overlayText='Below is a summary of the information we have extracted from your document using AI' />}
                    {includeDatasetField && isListView && <OverflowTooltip className={styles.mlDatasetInfo} overlayText='This is your opportunity to correct/confirm/complete any data points which AI was unable to deduce' />}
                    {isListView &&
                        <div className={styles.mlPreviousQueriesWrapper}>
                            <div className={styles.mlPreviousQueriesHeader}>Include Previously Confirmed Data</div>
                            <Toggle checked={includePreviousQueries} onChange={setIncludePreviousQueries} />
                        </div>}
                    {AIAnswerBody}
                </div>
                <div className={styles.buttonWrapper}>
                    <div className={styles.groupedButtonWrapper}>
                        <ButtonResize showIconButton={showIconButton} icon={ArrowLeft} onClick={closeModal} label='Close' disabled={awaitingUserConfirmation} />
                        {isInstanceView && <ButtonResize showIconButton={showIconButton} onClick={collapseAllFieldsAndSections} label={expandButtonLabel} icon={expandButtonIcon} />}
                    </div>
                    <div className={styles.groupedButtonWrapper}>
                        {showRunLatest && <ButtonResize showIconButton={showIconButton} icon={Repeat} onClick={() => runLatestMLQueries(mlData.documentId)} label='Run Latest AI' disabled={runLatestDisabled} />}
                        {awaitingUserConfirmation && <ButtonResize showIconButton={showIconButton} icon={SaveFile} onClick={confirm} label='Confirm & Save' disabled={updateDisabled} />}
                    </div>
                </div>
            </div>
        </DatasetModal>
    );
};
