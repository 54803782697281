import React, { useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';
import { Options } from 'react-select';
import { getOr, set, isNull, isUndefined, isEqual } from 'lodash/fp';

import { CalculationBuilder } from './CalculationBuilder';
import { calcConditionColors, Calculation, CalculationCondition, CalculatorField, CalculatorInput, CalculatorInputType, LogicalOperator, CalculationBuilder as CalculationBuilderType, CalculationType, CalculatorFieldType } from './constants';
import { DeleteButton } from '../button/DeleteButton';
import { Dropdown, DropdownOption } from '../dropdown/Dropdown';
import styles from './Calculator.module.scss';

const { white, lightGrey } = styles;

interface CalculationBlockProps {
    outputValue: CalculatorField[];
    selectedCalculation: CalculatorField;
    updateSelectedCalculation: (key: keyof CalculatorField, fieldValue: CalculationCondition | null | CalculationBuilderType | Calculation[]) => void;
    removeCalculation: (calculatorIndex: number) => void;
    calculation: Calculation[];
    output: CalculatorInput[];
    gracePeriod: CalculatorInput[];
    calculationCondition: CalculationCondition | null;
    setSelectedCalculationIndex: (index: number) => void;
    setSelectedBuilderIndex: (index: number | null) => void;
    calculationIndex: number;
    selectedBuilderIndex: number | null;
    selectedCalculationIndex: number;
    isEditing: boolean;
    getFontSize: (type: CalculatorInputType) => string;
    updateTextField: (calculationIndex: number, calculationProperty: 'calculation' | 'output' | 'gracePeriod', itemIndex: number, value: string, builderIndex?: number) => void;
    selectedCalculationBuilder: CalculationType;
    setSelectedCalculationBuilder: (type: CalculationType) => void;
    disabled: boolean;
    fieldType: CalculatorFieldType;
    showOutputField: boolean;
    showGracePeriod: boolean;
    borderColor: string;
}

export const CalculationBlock: React.FC<CalculationBlockProps> = ({
    outputValue,
    selectedCalculation,
    updateSelectedCalculation,
    removeCalculation,
    calculation,
    output,
    gracePeriod,
    calculationCondition,
    setSelectedCalculationIndex,
    setSelectedBuilderIndex,
    calculationIndex,
    selectedBuilderIndex,
    selectedCalculationIndex,
    isEditing,
    getFontSize,
    updateTextField,
    disabled,
    selectedCalculationBuilder,
    setSelectedCalculationBuilder,
    showOutputField,
    showGracePeriod,
    borderColor
}) => {
    const [hoveredBuilder, setHoveredBuilder] = useState<number[] | null>(null);
    const [hoveredOutput, setHoveredOutput] = useState<number | null>(null);
    const [hoveredGracePeriod, setHoveredGracePeriod] = useState<number | null>(null);

    const removeOutputRow = useCallback((rowIndex: number) => {
        const calculation = selectedCalculation.calculation.filter(({ index }) => index !== rowIndex).map((row, index) => set('index', index, row));
        updateSelectedCalculation('calculation', calculation);
        setSelectedBuilderIndex(rowIndex - 1);
    }, [updateSelectedCalculation, selectedCalculation, setSelectedBuilderIndex]);

    const removeFromBuilder = useCallback((outputIndex: number, itemIndex: number) => {
        updateSelectedCalculation('calculation', [...selectedCalculation.calculation.map(row => row.index === outputIndex ? set('builder', row.builder.filter((_, i) => i !== itemIndex), row) : row)]);
        setHoveredBuilder(null);
    }, [updateSelectedCalculation, selectedCalculation, setHoveredBuilder]);

    const builderClicked = useCallback((blockIndex: number, index: number, itemIndex: number) => {
        if (isEditing && !disabled) {
            if (blockIndex === selectedCalculationIndex && index === selectedBuilderIndex) {
                removeFromBuilder(index, itemIndex);
            } else {
                setSelectedCalculationIndex(blockIndex);
                setSelectedBuilderIndex(index);
            }
        }
    }, [selectedBuilderIndex, selectedCalculationIndex, removeFromBuilder, setSelectedBuilderIndex, setSelectedCalculationIndex, disabled, isEditing]);

    const removeItem = useCallback((itemIndex: number, type: CalculationType) => {
        updateSelectedCalculation(type, selectedCalculation[type].filter((_, i) => i !== itemIndex));
        setHoveredOutput(null);
    }, [updateSelectedCalculation, selectedCalculation, setHoveredOutput]);

    const outputGracePeriodClicked = useCallback((blockIndex: number, itemIndex: number, type: CalculationType) => {
        if (isEditing && !disabled) {
            blockIndex === selectedCalculationIndex ? removeItem(itemIndex, type) : setSelectedCalculationIndex(blockIndex);
        }
    }, [removeItem, selectedCalculationIndex, setSelectedCalculationIndex, disabled, isEditing]);

    const updateRowCondition = useCallback((dropdownValue: DropdownOption | Options<DropdownOption> | null, rowIndex: number) => {
        if (!isNull(dropdownValue)) {
            const value = (dropdownValue as DropdownOption).value;
            updateSelectedCalculation('calculation', selectedCalculation.calculation.map(row => row.index === rowIndex ? set('condition', value, row) : row));
        }
    }, [updateSelectedCalculation, selectedCalculation]);

    const conditionOptions = useMemo(() => [{ value: LogicalOperator.AND, label: LogicalOperator.AND }, { value: LogicalOperator.OR, label: LogicalOperator.OR }], []);

    const conditionSelect = useCallback((blockIndex: number, rowIndex: number) => {
        const conditionValue = conditionOptions.find(option => option.value === getOr(null, 'condition', outputValue[blockIndex].calculation.find(({ index }) => index === rowIndex))) || null;
        if (rowIndex !== 0) {
            return <div className={styles.conditionOption} style={{ width: isNull(conditionValue) ? '60px' : '38px', backgroundColor: isNull(conditionValue) ? lightGrey : white }}>
                <Dropdown
                    options={conditionOptions}
                    value={conditionValue}
                    onChange={value => updateRowCondition(value, rowIndex)}
                    menuPortalTarget={document.body}
                    hideIndicator
                    maxControlHeight='30px'
                    minControlHeight='30px'
                    disabled={disabled}
                    disableControlColor={white}
                    placeholder='AND/OR'
                    noControlBorder
                    controlBackgroundColor='transparent'
                    fontWeight={600}
                />
            </div>;
        }
        return null;
    }, [updateRowCondition, conditionOptions, disabled, outputValue]);

    const getBracketsInvalid = useCallback((blockIndex: number, builderIndex?: number) => {
        const builder = !isUndefined(builderIndex) ? outputValue[blockIndex].calculation[builderIndex].builder : outputValue[blockIndex][selectedCalculationBuilder];
        const brackets = builder.filter(({ type }) => type === CalculatorInputType.BRACKET).map(({ value }) => value);
        const remainingBrackets = brackets.reduce((acc, cur) => {
            if (cur === ')' && acc.charAt(acc.length - 1) === '(') {
                return acc.substring(0, acc.length - 1);
            }
            return `${acc}${cur}`;
        }, '');
        return !!remainingBrackets.length;
    }, [outputValue, selectedCalculationBuilder]);

    const getBuilderWidth = useCallback((rowIndex: number | null, isOutput: boolean, isGracePeriod: boolean) => {
        let widthAdjustment = 8;
        if (rowIndex && isEditing) {
            const removeRowWidth = 23;
            widthAdjustment = widthAdjustment + removeRowWidth;
        }
        if (isOutput || isGracePeriod) {
            const label = 45;
            widthAdjustment = widthAdjustment + label;
        }
        return `calc(100% - ${widthAdjustment}px)`;
    }, [isEditing]);

    const onBuilderMouseOver = useCallback((blockIndex: number, index: number, itemIndex: number) => blockIndex === selectedCalculationIndex && index === selectedBuilderIndex && setHoveredBuilder([index, itemIndex]), [selectedCalculationIndex, selectedBuilderIndex, setHoveredBuilder]);
    const onOutputMouseOver = useCallback((blockIndex: number, itemIndex: number) => blockIndex === selectedCalculationIndex && selectedCalculationBuilder === CalculationType.OUTPUT && setHoveredOutput(itemIndex), [selectedCalculationIndex, setHoveredOutput, selectedCalculationBuilder]);
    const onGracePeriodMouseOver = useCallback((blockIndex: number, itemIndex: number) => blockIndex === selectedCalculationIndex && selectedCalculationBuilder === CalculationType.GRACE_PERIOD && setHoveredGracePeriod(itemIndex), [selectedCalculationIndex, setHoveredGracePeriod, selectedCalculationBuilder]);
    const showOnHover = useCallback((blockIndex: number, builderIndex: number, index: number) => isEditing && !disabled && !isNull(hoveredBuilder) && isEqual(blockIndex, selectedCalculationIndex) && isEqual(hoveredBuilder, [builderIndex, index]), [hoveredBuilder, selectedCalculationIndex, disabled, isEditing]);
    const showOutputOnHover = useCallback((blockIndex: number, index: number) => isEditing && !disabled && !isNull(hoveredOutput) && isEqual(blockIndex, selectedCalculationIndex) && selectedCalculationBuilder === CalculationType.OUTPUT && isEqual(hoveredOutput, index), [hoveredOutput, selectedCalculationIndex, disabled, isEditing, selectedCalculationBuilder]);
    const showOnGracePeriodHover = useCallback((blockIndex: number, index: number) => isEditing && !disabled && !isNull(hoveredGracePeriod) && selectedCalculationBuilder === CalculationType.GRACE_PERIOD && isEqual(blockIndex, selectedCalculationIndex) && isEqual(hoveredGracePeriod, index), [hoveredGracePeriod, selectedCalculationIndex, disabled, isEditing, selectedCalculationBuilder]);

    const calculationConditionBgColor = useMemo(() => !isNull(calculationCondition) ? calcConditionColors[calculationCondition] : undefined, [calculationCondition]);
    const showConditionalBuilder = calculationCondition !== CalculationCondition.ELSE;

    const showCalculationBuilderMarginBottom = useMemo(() => showOutputField || showGracePeriod || calculation.length > 1, [showGracePeriod, showOutputField, calculation.length]);

    return (
        <div className={classnames(styles.blockWrapper, { [styles.selectedBlock]: calculationIndex === selectedCalculationIndex && isEditing })} key={calculationIndex} style={{ border: `1px solid ${borderColor}` }}>
            {calculationCondition &&
                <div className={styles.blockHeader}>
                    <div className={styles.calculationConditionWrapper} onClick={() => setSelectedCalculationIndex(calculationIndex)}>
                        <div className={styles.calculationCondition} style={{ backgroundColor: calculationConditionBgColor }}>{calculationCondition}</div>
                    </div>
                    {isEditing && !disabled && <DeleteButton onClick={() => removeCalculation!(calculationIndex)} fontSize={16} withBorder />}
                </div>}
            {showConditionalBuilder && calculation.map(row => {
                const { index, builder } = row;
                const bracketsInvalid = getBracketsInvalid(calculationIndex, index);
                return (
                    <div className={styles.builderWithConditionalWrapper} key={index}>
                        {conditionSelect(calculationIndex, index)}
                        <CalculationBuilder
                            builder={builder}
                            builderIndex={index}
                            selectedBuilderIndex={selectedBuilderIndex}
                            calculationIndex={calculationIndex}
                            selectedCalculationIndex={selectedCalculationIndex}
                            getFontSize={getFontSize}
                            isEditing={isEditing}
                            onClickBuilder={() => { setSelectedBuilderIndex(index); setSelectedCalculationIndex(calculationIndex); }}
                            onClickItem={itemIndex => builderClicked(calculationIndex, index, itemIndex)}
                            onMouseOver={itemIndex => onBuilderMouseOver(calculationIndex, index, itemIndex)}
                            onMouseOut={() => setHoveredBuilder(null)}
                            showOnHover={itemIndex => showOnHover(calculationIndex, index, itemIndex)}
                            getBuilderWidth={getBuilderWidth}
                            showRemoveRow={index !== 0}
                            deleteBuilder={() => removeOutputRow(index)}
                            bracketsInvalid={bracketsInvalid}
                            selectedCalculationBuilder={selectedCalculationBuilder}
                            includeMarginBottom={showCalculationBuilderMarginBottom}
                            updateTextField={(value, itemIndex) => updateTextField(calculationIndex, 'calculation', itemIndex, value, index)}
                        />
                    </div>
                );
            })}
            {showOutputField &&
                <CalculationBuilder
                    builder={output}
                    builderIndex={null}
                    selectedBuilderIndex={selectedBuilderIndex}
                    calculationIndex={calculationIndex}
                    selectedCalculationIndex={selectedCalculationIndex}
                    getFontSize={getFontSize}
                    isEditing={isEditing}
                    onClickBuilder={() => { setSelectedBuilderIndex(null); setSelectedCalculationIndex(calculationIndex); setSelectedCalculationBuilder(CalculationType.OUTPUT); }}
                    onClickItem={itemIndex => outputGracePeriodClicked(calculationIndex, itemIndex, CalculationType.OUTPUT)}
                    onMouseOver={itemIndex => onOutputMouseOver(calculationIndex, itemIndex)}
                    onMouseOut={() => setHoveredOutput(null)}
                    showOnHover={itemIndex => showOutputOnHover(calculationIndex, itemIndex)}
                    getBuilderWidth={getBuilderWidth}
                    showRemoveRow={false}
                    bracketsInvalid={getBracketsInvalid(calculationIndex)}
                    isOutput
                    updateTextField={(value, itemIndex) => updateTextField(calculationIndex, 'output', itemIndex, value)}
                    selectedCalculationBuilder={selectedCalculationBuilder}
                    builderType={CalculationType.OUTPUT}
                    includeMarginBottom={showGracePeriod}
                />
            }
            {showGracePeriod &&
                <CalculationBuilder
                    builder={gracePeriod}
                    builderIndex={null}
                    selectedBuilderIndex={selectedBuilderIndex}
                    calculationIndex={calculationIndex}
                    selectedCalculationIndex={selectedCalculationIndex}
                    getFontSize={getFontSize}
                    isEditing={isEditing}
                    onClickBuilder={() => { setSelectedBuilderIndex(null); setSelectedCalculationIndex(calculationIndex); setSelectedCalculationBuilder(CalculationType.GRACE_PERIOD); }}
                    onClickItem={itemIndex => outputGracePeriodClicked(calculationIndex, itemIndex, CalculationType.GRACE_PERIOD)}
                    onMouseOver={itemIndex => onGracePeriodMouseOver(calculationIndex, itemIndex)}
                    onMouseOut={() => setHoveredGracePeriod(null)}
                    showOnHover={itemIndex => showOnGracePeriodHover(calculationIndex, itemIndex)}
                    getBuilderWidth={getBuilderWidth}
                    showRemoveRow={false}
                    bracketsInvalid={getBracketsInvalid(calculationIndex)}
                    selectedCalculationBuilder={selectedCalculationBuilder}
                    isGracePeriod
                    builderType={CalculationType.GRACE_PERIOD}
                    includeMarginBottom={false}
                    updateTextField={(value, itemIndex) => updateTextField(calculationIndex, 'gracePeriod', itemIndex, value)}
                />
            }
        </div>
    );
};
