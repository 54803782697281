import React, { useCallback, useMemo } from 'react';
import { isNull } from 'lodash';
import { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux';
import { Text } from '../../shared/text/Text';
import { Toggle } from '../../shared/toggle';
import { getAllDocumentNames } from '../documents/store';
import styles from './AIManager.module.scss';
import { deleteDocumentQuery, DocumentQuery, getAgreementTypeSystemInfo, getCurrentDocumentQueries, getIsEditingDocumentQueries, getSelectedAIAgreementTypeId, SystemIdInfoUI, updateDocumentQuery, updateQuerySystemInfo } from './store';
import { Scrollable } from '../../shared/scrollable/Scrollable';
import { LongText } from '../../shared/longtext/LongText';
import { OverflowTooltip } from '../../shared/tooltip';
import { Delete } from '../../shared/icons';
import { IconButton } from '../../shared/button/IconButton';
import { InformationTooltip } from '../../shared/tooltip/InformationTooltip';
import { Dropdown, DropdownOption } from '../../shared/dropdown/Dropdown';
import { DatasetFieldType, SingleDatasetFieldType } from '../../datasets/store';
import { FieldValue } from '../../datasets/instances/store';
import { DefaultValue } from './DefaultValue';

const { red } = styles;

const defaultSystemIdInfo: SystemIdInfoUI = {
    dropdownListId: null,
    isMultiSelect: false,
    type: null,
    systemId: ''
};

export const AIQuery: React.FC = () => {
    const dispatch = useAppDispatch();

    const selectedAgreementTypeId = useAppSelector(getSelectedAIAgreementTypeId);
    const documentNames = useAppSelector(getAllDocumentNames);
    const currentQueries = useAppSelector(getCurrentDocumentQueries);
    const isEditing = useAppSelector(getIsEditingDocumentQueries);
    const agreementTypeSystemIdInfo = useAppSelector(getAgreementTypeSystemInfo);
    const relevantDocumentNames = useMemo(() => documentNames.filter(({ agreementTypeId }) => agreementTypeId === selectedAgreementTypeId).map(({ documentName, documentNameId }) => ({ documentName, documentNameId })), [documentNames, selectedAgreementTypeId]);

    const deleteNewQuery = useCallback((queryOrder: number) => dispatch(deleteDocumentQuery(queryOrder)), [dispatch]);

    const updateQuery = useCallback((queryOrder: number, key: keyof DocumentQuery, value: string | number | number[] | FieldValue) => dispatch(updateDocumentQuery(queryOrder, key, value)), [dispatch]);
    const updateQuerySystemData = useCallback((queryOrder: number, value: SystemIdInfoUI) => dispatch(updateQuerySystemInfo(queryOrder, value)), [dispatch]);

    const agreementTypeSystemIdOptions = useMemo(() => agreementTypeSystemIdInfo.map(({ systemId }) => ({ value: systemId, label: systemId })), [agreementTypeSystemIdInfo]);

    const updateDocumentNameIds = useCallback((queryOrder: number, documentNameId: number, documentNameIds: number[]) => {
        const alreadyExists = documentNameIds.includes(documentNameId);
        const updatedDocumentNameIds = alreadyExists ? documentNameIds.filter(id => id !== documentNameId) : [...documentNameIds, documentNameId];
        dispatch(updateDocumentQuery(queryOrder, 'documentNameIds', updatedDocumentNameIds));
    }, [dispatch]);

    const updateSystemId = useCallback((dropdownValue: DropdownOption | Options<DropdownOption> | null, queryOrder: number) => {
        let value = defaultSystemIdInfo;
        if (!isNull(dropdownValue)) {
            const systemId = (dropdownValue as DropdownOption).value;
            const foundSystemInfo = agreementTypeSystemIdInfo.find(systemIdInfo => systemIdInfo.systemId === systemId);
            if (foundSystemInfo) {
                value = foundSystemInfo;
            }
        }
        updateQuerySystemData(queryOrder, value);
    }, [updateQuerySystemData, agreementTypeSystemIdInfo]);

    const queryKeyAlreadyExists = useCallback((queryOrder: number, queryKey: string) => currentQueries.filter(query => query.queryOrder !== queryOrder).map(({ queryKey }) => queryKey).includes(queryKey), [currentQueries]);

    const getDefaultValueElement = useCallback((queryOrder: number, defaultAnswer: FieldValue, fieldType: SingleDatasetFieldType | null, dropdownListId: number | null, isMultiSelect: number, isEdgeCase: number) => {
        if (isNull(fieldType) || ![DatasetFieldType.DROPDOWN, DatasetFieldType.TEXT, DatasetFieldType.LONGTEXT, DatasetFieldType.NUMBER].includes(fieldType) || isEdgeCase === 1) {
            return null;
        }
        return (
            <div className={styles.aiDataPointWrapper}>
                <div className={styles.aiDataPointLabel}>Default Value</div>
                <DefaultValue defaultAnswer={defaultAnswer} updateQuery={value => updateQuery(queryOrder, 'defaultAnswer', value)} fieldType={fieldType} dropdownListId={dropdownListId} isMultiSelect={isMultiSelect} isEditing={isEditing} />
            </div>
        );
    }, [updateQuery, isEditing]);

    if (currentQueries.length === 0) {
        return (
            <div className={styles.aiQueries}>
                <div className={styles.emptyQueries}>You have no questions configured for the agreement type</div>
            </div>
        );
    }

    return (
        <div className={styles.aiQueries}>
            <Scrollable>
                {currentQueries.map(({ documentQueryId, queryKey, systemId, correctQueries, documentNameIds, question, totalQueries, isEdgeCase, multiRowQuery, partyRowExists, queryOrder, guidanceNotes, fieldType, dropdownListId, isMultiSelect, defaultAnswer }) => {
                    const percentage = totalQueries > 0 ? (correctQueries / totalQueries) * 100 : 0;
                    const existingDisabled = !isEditing || isEditing && !isNull(documentQueryId);
                    const queryKeyBorder = isNull(documentQueryId) && queryKey.length > 0 && queryKeyAlreadyExists(queryOrder, queryKey) ? red : undefined;
                    const showEdgeCaseTooltip = isNull(documentQueryId) && isEditing;
                    const systemIdValue = { label: systemId, value: systemId };
                    return (
                        <div className={styles.aiQueryWrapper} key={documentQueryId} id={`ai-query-${queryOrder}`}>
                            <div className={styles.aiDataPointWrapper}>
                                <div className={styles.aiDataPointLabel}>Question</div>
                                <LongText testId='ai-query-question' disabled={!isEditing} value={question} marginBottom='0px' onChange={value => updateQuery(queryOrder, 'question', value)} width='70%' />
                            </div>
                            <div className={styles.aiDataPointWrapper}>
                                <div className={styles.aiDataPointLabel}>Guidance Notes</div>
                                <LongText testId='ai-query-guidance-notes' disabled={!isEditing} value={guidanceNotes} marginBottom='0px' onChange={value => updateQuery(queryOrder, 'guidanceNotes', value)} width='70%' />
                            </div>
                            <div className={styles.aiDataPointWrapper}>
                                <div className={styles.aiDataPointLabel}>Key</div>
                                <Text testId='ai-query-key' borderColour={queryKeyBorder} disabled={existingDisabled} value={queryKey} onChange={e => updateQuery(queryOrder, 'queryKey', e.target.value)} marginBottom='0px' width='70%' />
                            </div>
                            <div className={styles.aiDataPointWrapper}>
                                <div className={styles.aiDataPointLabel}>System ID</div>
                                <div style={{ width: '70%' }}>
                                    <Dropdown
                                        value={systemIdValue}
                                        options={agreementTypeSystemIdOptions}
                                        onChange={e => updateSystemId(e, queryOrder)}
                                        testId='ai-query-system-id'
                                        disabled={existingDisabled}
                                    />
                                </div>
                            </div>
                            {getDefaultValueElement(queryOrder, defaultAnswer, fieldType, dropdownListId, isMultiSelect, isEdgeCase)}
                            <div className={styles.aiDataPointWrapper}>
                                <div className={styles.aiDataPointLabel}>
                                    Edge Case?
                                    {showEdgeCaseTooltip &&
                                        <div className={styles.edgeCaseTooltip}>
                                            <InformationTooltip content='Any question that relates to more than one field is considered an edge case.' />
                                        </div>
                                    }
                                </div>
                                <Toggle testId='ai-query-edge-case' disabled={existingDisabled} checked={[isEdgeCase, multiRowQuery, partyRowExists].includes(1)} onChange={value => updateQuery(queryOrder, 'isEdgeCase', value ? 1 : 0)} />
                            </div>
                            <div className={styles.documentNamesWrapper}>
                                {relevantDocumentNames.map(({ documentNameId, documentName }) => (
                                    <div className={styles.aiDataPointWrapper} key={documentNameId}>
                                        <div className={styles.aiDataPointLabel}>
                                            <OverflowTooltip overlayText={documentName} />
                                        </div>
                                        <Toggle testId={`ai-query-document-name-${documentName}`} disabled={!isEditing} checked={documentNameIds.includes(documentNameId!)} onChange={() => updateDocumentNameIds(queryOrder, documentNameId!, documentNameIds)} />
                                    </div>
                                ))}
                            </div>
                            {documentQueryId &&
                                <div className={styles.percentageWrapper}>
                                    <div className={styles.percentageBar}>
                                        <div className={styles.successPercentage} style={{ width: `${percentage}%` }} />
                                    </div>
                                    <div className={styles.successInfo}>
                                        <div className={styles.successString}>
                                            <OverflowTooltip overlayText={`Success Rate: ${correctQueries} / ${totalQueries}`} />
                                        </div>
                                        <div className={styles.displayPercentage}>{percentage}%</div>
                                    </div>
                                </div>
                            }
                            {isNull(documentQueryId) && isEditing &&
                                <div className={styles.deleteQuery}>
                                    <IconButton icon={Delete} onClick={() => deleteNewQuery(queryOrder)} color={red} />
                                </div>
                            }
                        </div>
                    );
                })}
            </Scrollable>
        </div>
    );
};
